
import { defineComponent, inject, reactive, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { Form, Field } from "vee-validate";
import { checkEmail, checkEmailRetype, checkPassword, checkPasswordRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { loginStatus } from "@/modules/loginStatus";
import { CognitoItem, CognitoItemKey } from "@/modules/cognitoItem";

export default defineComponent({
    name: "Signup",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const { cognitoInfo } = inject(CognitoItemKey) as CognitoItem;
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            email: checkEmail,
            emailRetype: checkEmailRetype,
            password: checkPassword,
            passwordRetype: checkPasswordRetype
        });

        const passwordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const passwordRetypeToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean } 
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * 登録ボタン押下時処理
         * @async
         */
        const register = async () => {
            try {
                // Cognito登録
                const signupResponse = await sidClient.signUp({
                    type: "Email",
                    email: cognitoInfo.email,
                    password: cognitoInfo.password
                });

                // ユーザ名を取得
                cognitoInfo.userName = signupResponse.user.getUsername();

                if (signupResponse.userConfirmed) {
                    // 念の為サインアウト
                    await sidClient.signOut();
                    loginStatus.value = false;

                    // 既にユーザ確認済。通常はありえない
                    await sidClient.signIn(cognitoInfo.userName, cognitoInfo.password, true);
                    await store.dispatch("fetchUserInfo").finally(() => {
                        // マイページトップ画面へ遷移
                        router.push({ name: "MypageTop" });
                    });
                } else {
                    // ユーザ未確認
                    router.push({ name: "SignupCode" });
                }
            } catch (e) {
                if (e.code === "UsernameExistsException") {
                    // 既にメールアドレスが登録されている場合
                    errorMsgRef.value = "メールアドレスは既に登録されています。";
                } else if (e.code === "UserLambdaValidationException") {
                    // 登録が許可されていない場合
                    errorMsgRef.value = "このメールアドレスは登録できません。";
                } else {
                    errorMsgRef.value = "ユーザ登録に失敗しました。後ほど再度お試し下さい。";
                }
            }
        };

        return {
            isDeterringRef,
            asyncDeterringWrap,
            cognitoInfo,
            errorMsgRef,
            schema,
            PASSWORD_MAX_LENGTH,
            passwordToggle,
            passwordRetypeToggle,
            switchPasswordFieldType,
            register
        };
    },
});
