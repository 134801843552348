import { SidUserClient } from "@sid/sid_client";
import { BaseInfo, CommonAttrInfo, LinkageUserData, ManageInfo, MemberAgreementInfo, ShippingInfo, SiteInfo, UserBase, UserRimacomi } from "@/commons/interfaces";
import { InjectionKey, reactive } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import RimacomiProfile from "@/components/Linkage/Sites/RimacomiProfile.vue";
import RimacomiConfirm from "@/components/Linkage/Sites/RimacomiConfirm.vue";
import RimacomiWithdrawCustom from "@/components/Linkage/Sites/RimacomiWithdrawCustom.vue";
import RimacomiProfileCompleteCustom from "@/components/Linkage/Sites/RimacomiProfileCompleteCustom.vue";

// リマコミ＋関連定数定義
/** 連携ID */
export const RIMACOMI_LINKAGE_ID = 8;
/** 連携名称 */
export const RIMACOMI_LINKAGE_NAME = "Rimacomi";
/** 連携パス */
export const RIMACOMI_PATH = RIMACOMI_LINKAGE_NAME.toLowerCase();
/** サイト固有情報APIパス */
export const RIMACOMI_SITE_UNIQ_API_PATH = "/rimacomi/me";
/** デフォルトコールバックURL */
export const RIMACOMI_DEFAULT_CALLBACK_URL = process.env.VUE_APP_RIMACOMI_DEFAULT_CALLBACK_URL;
/** 許容コールバックURL */
export const RIMACOMI_ACCEPT_CALLBACK_URLS = process.env.VUE_APP_RIMACOMI_ACCEPT_CALLBACK_URLS.split(",").map((url: string) => `^${url}(\\?.*)?$`);
/** サービス規約URL */
export const RIMACOMI_SERVICE_TERM_URL = process.env.VUE_APP_RIMACOMI_SERVICE_TERM_URL;
/** メールアドレスドメイン */
export const RIMACOMI_EMAIL_DOMAINS = [
    "rimacomiplus.jp"
];

/** 基本属性情報入力 */
export const RIMACOMI_PROFILE_COMPONENT = RimacomiProfile;
/** 基本属性情報確認 */
export const RIMACOMI_CONFIRM_COMPONENT = RimacomiConfirm;
/** 退会画面カスタムパーツ */
export const RIMACOMI_WITHDRAW_CUSTOM_COMPONENT = RimacomiWithdrawCustom;
/** プロフィール完了画面カスタムパーツ */
export const RIMACOMI_PROFILE_COMPLETE_CUSTOM_COMPONENT = RimacomiProfileCompleteCustom;

/**
 * リマコミ＋連携画面用基本属性情報管理クラス
 */
export class RimacomiUserBase implements LinkageUserData<UserBase> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> = Symbol("RimacomiUserBase");

    /** 基本属性情報 */
    public readonly data: UserBase = reactive(new UserBase());
    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserBase());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserBase {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserBase): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.baseInfo = Object.assign(new BaseInfo(), obj.baseInfo);
        this.data.shippingInfo = obj.shippingInfo?.map(inf => Object.assign(new ShippingInfo(), inf)) || [];
        this.data.siteInfo = obj.siteInfo?.map(inf => Object.assign(new SiteInfo(), inf)) || [];
        this.data.commonAttrInfo = Object.assign(new CommonAttrInfo(), obj.commonAttrInfo);
        this.data.memberAgreeInfo = Object.assign(new MemberAgreementInfo(), obj.memberAgreeInfo);
        this.data.manageInfo = Object.assign(new ManageInfo(), obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.getUserAttr()
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserBase();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData TODO
     */
    public checkRequired(): boolean {
        // 基本属性情報の有無
        if (!this.data.identityId) return false;
        // 登録者情報
        // if (!this.data.baseInfo.firstName) return false;
        // if (!this.data.baseInfo.lastName) return false;
        // if (!this.data.baseInfo.firstKana) return false;
        // if (!this.data.baseInfo.lastKana) return false;
        // if (!this.data.baseInfo.zipCode) return false;
        // if (!this.data.baseInfo.prefecture) return false;
        // if (!this.data.baseInfo.address) return false;
        // if (!this.data.baseInfo.building) return false;
        if (!this.data.baseInfo.email) return false;
        // if (!this.data.baseInfo.tel) return false;
        // 属性情報
        // if (!this.data.commonAttrInfo.gender) return false;
        // if (!this.data.commonAttrInfo.birthday) return false;
        // if (!this.data.commonAttrInfo.imagePath) return false;
        // if (!this.data.commonAttrInfo.nickName) return false;
        // if (this.data.commonAttrInfo.isMarried === null || this.data.commonAttrInfo.isMarried === undefined) return false;
        // if (this.data.commonAttrInfo.hasChild === null || this.data.commonAttrInfo.hasChild === undefined) return false;
        // if (!this.data.commonAttrInfo.bloodType) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> {
        return RimacomiUserBase.injectionKey;
    }

}

/**
 * リマコミ＋連携画面用サイト固有情報管理クラス
 */
export class RimacomiSiteUniq implements LinkageUserData<UserRimacomi> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserRimacomi>>> = Symbol("UserRimacomi");

    /** リマコミ＋サイト固有情報 */
    public readonly data: UserRimacomi = reactive(new UserRimacomi());

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserRimacomi());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserRimacomi {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserRimacomi): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.rimacomiId = obj.rimacomiId;
        this.data.manageInfo = Object.assign({}, obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.requestApi({ method: "GET", path: RIMACOMI_SITE_UNIQ_API_PATH })
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserRimacomi();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の有無
        if (this.data.rimacomiId === null || this.data.rimacomiId === undefined) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserRimacomi>>> {
        return RimacomiSiteUniq.injectionKey;
    }
}

/**
 * リマコミ＋IDの禁止文字列一覧(正規表現)
 */
export const RIMACOMA_ID_FORBIDDEN_STR_LIST = [
    /^index$/,
    /^top$/,
    /^help$/,
    /^about$/,
    /^security$/,
    /^contact$/,
    /^connect$/,
    /^support$/,
    /^faq$/,
    /^form$/,
    /^mail$/,
    /^update$/,
    /^mobile$/,
    /^phone$/,
    /^portal$/,
    /^info.*$/,
    /^directory.*$/,
    /^docs.*$/,
    /^business$/,
    /^privacy.*$/,
    /^term.*$/,
    /^welcome$/,
    /^buzzman.*$/,
    /^api.*$/,
    /^csapi.*$/,
    /^v1$/,
    /^v2$/,
    /^v3$/,
    /^oembeded.*$/,
    /^api.*$/,
    /^twitter.*$/,
    /^facebook$/,
    /^tiktok.*$/,
    /^google.*$/,
    /^instagram.*$/,
    /^ranking$/,
    /^users$/,
    /^review$/,
    /^setsession$/,
    /^creator$/,
    /^creators$/,
    /^recommend.*$/,
    /^follow.*$/,
    /^tag$/,
    /^tags$/,
    /^store$/,
    /^getsession$/,
    /^store$/,
    /^signupAgree$/,
    /^signinSuccess$/,
    /^signup$/,
    /^signin$/,
    /^sitemap.xml$/,
    /^signin$/,
    /^tatesuku$/,
    /^tatesukumanga$/,
    /^mailmagazine$/,
    /^sns$/,
    /^tutorial$/,
    /^genre$/,
    /^signin$/,
    /^latest$/,
    /^search$/,
    /^community$/,
    /^content$/,
    /^contents$/,
    /^documentrequest$/,
    /^payment.*$/,
    /^oauth.*$/,
    /^guide$/,
    /^account.*$/,
    /^user.*$/,
    /^system.*$/,
    /^root.*$/,
    /^guest.*$/,
    /^admin.*$/,
    /^administrator.*$/,
    /^test.*$/,
    /^hoge.*$/,
    /^.*crack.*$/,
    /^.*virus.*$/,
    /^.*spam.*$/,
    /^aaa.*$/,
    /^bbb.*$/,
    /^ccc.*$/,
    /^xxx.*$/,
    /^yyy.*$/,
    /^zzz.*$/,
    /^book.*$/,
    /^books.*$/,
    /^manga.*$/,
    /^story.*$/,
    /^stories.*$/,
    /^article.*$/,
    /^articles.*$/,
    /^event.*$/,
    /^events.*$/,
    /^.*cork.*$/,
    /^sady.*$/,
    /^mandy.*$/,
    /^character$/,
    /^series$/,
    /^comment$/,
    /^relay$/,
    /^webmanga$/,
    /^plan$/,
    /^column$/,
    /^illust$/,
    /^admanga$/,
    /^mangaName$/,
    /^recommend.*$/,
    /^pop$/,
    /^recruit$/,
    /^mangawor$/,
];
