
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Field, Form } from "vee-validate";
import { checkNewPassword, checkNewPasswordRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isAuthFailed, isSiteRegistered } from "@/commons/commonUtility";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";

export default defineComponent({
    name: "LinkageNewPassword",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, setNotRelease, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, fetchToken, routeNames } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        const newPasswordRef = ref("");
        const newPasswordRetypeRef = ref("");
        const errorMsgRef = ref("");
        const returnUrlRef = ref("");

        const schema = Yup.object().shape({
            newPassword: checkNewPassword,
            newPasswordRetype: checkNewPasswordRetype
        });

        const newPasswordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const newPasswordRetypeToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean }
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * パスワード設定
         * @async
         *
         */
        const setPassword = async () => {
            let userName;
            try {
                // パスワード設定要求
                await sidClient.continueSignInNewPassword(newPasswordRef.value);
                // 認証ユーザ名
                userName = (await sidClient.getUserData()).Username;

                // 基本属性情報取得
                const promiseUserBase = userBaseItem.fetchUserData(sidClient);
                // サイト固有情報取得
                const promiseSiteUniq = siteUniqItem.fetchUserData(sidClient);
                // 待ち合わせ
                await Promise.all([promiseUserBase, promiseSiteUniq]);

                // ユーザ名格納
                userBaseItem.userData.userName = userName;
                siteUniqItem.userData.userName = userName;

                // セッションストレージ格納
                userBaseItem.storeStorage();
                siteUniqItem.storeStorage();
            } catch (e) {
                if (isAuthFailed(e)) {
                    if (linkageInfo.email) {
                        if (route.name === routeNames.SIGNUP_NEW_PASSWORD) {
                            // 本人確認画面へ遷移
                            await router.push({ name: routeNames.SIGNUP_IDENTIFICATION, query: { retUrl: returnUrlRef.value }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                        } else {
                            // 本人確認画面へ遷移
                            await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: returnUrlRef.value }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                        }
                    } else {
                        // ログイン画面へ遷移
                        await router.push({ name: routeNames.LOGIN, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                    }
                } else {
                    if (e.message === "Failed to get information.") {
                        await router.push({ name: routeNames.GENERAL_ERROR, params: { message: "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。" } });
                    } else {
                        errorMsgRef.value = "パスワード設定に失敗しました。後ほど再度お試し下さい。";   
                    }
                }
                return;
            }

            // 遷移先判定
            // サービスサイトから各種変更/退会の要求で遷移してくる場合
            if (returnUrlRef.value) {
                await router.push({ name: returnUrlRef.value });
                return;
            }

            // ログイン画面からの遷移の場合

            // サイト登録有無
            const isSiteRegd = isSiteRegistered(linkageInfo?.linkageId, userBaseItem.userData);

            // サイト毎の必須情報チェック
            const chkReqd = userBaseItem.checkRequired() && siteUniqItem.checkRequired();

            // 必須情報が揃っている
            if (isSiteRegd && chkReqd) {
                // トークンをコールバックする
                try {
                    await fetchToken(sidClient);
                    setNotRelease();
                    window.location.href = linkageInfo.callbackUrlWithToken as string;
                } catch (e) {
                    await router.push({ name: routeNames.GENERAL_ERROR, params: { message: "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。" } });
                }
            } else {
                // 会員情報更新画面へ
                await router.push({ name: `${routeNames.LOGIN_PROFILE}Child` });
            }
        };

        onMounted((): void => {
            returnUrlRef.value = route.query.retUrl as string;
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            newPasswordRef,
            newPasswordRetypeRef,
            errorMsgRef,
            schema,
            PASSWORD_MAX_LENGTH,
            newPasswordToggle,
            newPasswordRetypeToggle,
            switchPasswordFieldType,
            setPassword,
            linkageInfo
        };
    },
});
