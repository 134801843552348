import { checkImageFormat, readFile } from "@/commons/imageFormatUtility";
import { InjectionKey, reactive, ref } from "vue";

export const createImageItem = () => {
    const image = reactive<{
        path: string;
        name: string;
        text: string;
        file: Blob;
    }>({
        path: "",
        name: "",
        text: "",
        file: new Blob()
    });

    const fileUploadRef = ref(null);
    const isDeleteRef = ref(false);
    const imageErrorMsgRef = ref("");

    /**
     * プロフィール画像設定
     * @async
     * @param event
     */
    const setImage = async(event: any) => {
        const file = (event.target.files || event.dataTransfer)[0];

        // 画像選択後、再度ファイル選択ダイアログを表示してキャンセルボタンを押下した場合、
        // file:undefinedとなり、エラーが発生するため、回避
        // ※Firefoxは、キャンセル前の値が維持される
        if (!file) {
            return;
        }
    
        const binaryData = await readFile(file);

        // 画像フォーマット判定
        if (!checkImageFormat(binaryData)) {
            imageErrorMsgRef.value = "使用可能な画像は、jpeg/gif/png/bmp形式です。";
            return;
        }
        imageErrorMsgRef.value = "";

        if (file.type.startsWith("image/")) {
            image.path = window.URL.createObjectURL(file);
            image.name = file.name;
            image.text = file.type;
            image.file = file;
        }

        // 削除後、再度同じ画像を選択できるようにイベントを空にする
        event.target.value = "";
    };

    /**
     * プロフィール画像削除
     */
    const delImage = () => {
        image.path = "";
        image.name = "";
        image.text = "";
        image.file = new Blob();
    };

    /**
     * 画像アップロードダイアログ起動
     */
    const openImageUpload = async() => {
        await (fileUploadRef.value as any).click();
    };

    /**
     * 画像操作
     */
    const operationImage = async() => {
        (image.path) ? delImage() : await openImageUpload();
    };

    return {
        image,
        fileUploadRef,
        isDeleteRef,
        imageErrorMsgRef,
        setImage,
        delImage,
        openImageUpload,
        operationImage
    };
};

export type ImageItem = ReturnType<typeof createImageItem>;

export const ImageItemKey: InjectionKey<ImageItem> = Symbol("ImageItem");