
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { loginStatus } from "@/modules/loginStatus";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";

export default defineComponent({
    name: "LinkageIdentification",
    props: {
        message: { type: String },
    },
    setup(props) {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, routeNames } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        /* Reactive */
        const passwordRef = ref("");
        const disableAutoLoginRef = ref(false);
        const autoLoginRef = ref(true);
        const errorMsgRef = ref("");
        const returnUrlRef = ref("");

        const passwordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        if (!linkageInfo.email) {
            // ログイン画面でlinkageInfo.emailを空にしているため、ブラウザバッグ時エラー
            router.push({ name: routeNames.GENERAL_ERROR, params: { message: "無効な操作です。" } });
        }

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean }
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * ログイン
         * @async
         */
        const login = async () => {
            try {
                // 念の為サインアウト
                await sidClient.signOut();
                loginStatus.value = false;

                // セッションストレージ削除
                userBaseItem.removeStorage();
                siteUniqItem.removeStorage();

                // 自動ログイン無効化判断
                if (disableAutoLoginRef.value) {
                    autoLoginRef.value = false;
                }

                // サインイン
                let userName;
                try {
                    const result = await sidClient.signIn(linkageInfo.email as string, passwordRef.value, autoLoginRef.value);
                    if (result === "NEW_PASSWORD_REQUIRED") {
                        // 管理画面でアカウント登録された場合、または、管理画面でパスワード変更を行った場合
                        if (route.name === routeNames.SIGNUP_IDENTIFICATION) {
                            await router.push({
                                name: routeNames.SIGNUP_NEW_PASSWORD,
                                query: { retUrl: returnUrlRef.value }
                            });
                        } else {
                            await router.push({
                                name: routeNames.NEW_PASSWORD,
                                query: { retUrl: returnUrlRef.value }
                            });
                        }
                        return;
                    } else if (result === "RESET_PASSWORD_REQUIRED") {
                        // Cognitoでパスワード変更された場合
                        if (route.name === routeNames.SIGNUP_IDENTIFICATION) {
                            await router.push({
                                name: routeNames.SIGNUP_SET_PASSWORD,
                                // eslint-disable-next-line
                                query: {reset_email: linkageInfo.email}
                            });
                        } else {
                            await router.push({
                                name: routeNames.SET_PASSWORD,
                                // eslint-disable-next-line
                                query: {reset_email: linkageInfo.email}
                            });
                        }
                        return;
                        
                    }
                    userName = (await sidClient.getUserData()).Username;
                } catch (e) {
                    if (e.code == "UserNotConfirmedException") {
                        // 実店舗ユーザ（ユーザ名でログイン）の場合のみだが、
                        // 実店舗ユーザは必ず確認済みのステータスとなる仕様のため、デッドコード
                        errorMsgRef.value = "ログインできませんでした。";
                    } else {
                        errorMsgRef.value = "ログインできませんでした。メールアドレスとパスワードをご確認ください。";
                    }
                    return;
                }

                // 画面遷移
                if (route.name === routeNames.SIGNUP_IDENTIFICATION) {
                    // 新規会員登録の場合

                    // 基本属性情報取得
                    const promiseUserBase = userBaseItem.fetchUserData(sidClient);
                    // サイト固有情報取得
                    const promiseSiteUniq = siteUniqItem.fetchUserData(sidClient);
                    // 待ち合わせ
                    await Promise.all([promiseUserBase, promiseSiteUniq]);

                    // ユーザ名格納
                    userBaseItem.userData.userName = userName;
                    siteUniqItem.userData.userName = userName;

                    // セッションストレージ格納
                    userBaseItem.storeStorage();
                    siteUniqItem.storeStorage();

                    // 会員情報登録画面へ遷移
                    await router.push({ name: `${routeNames.SIGNUP_PROFILE}Child` });
                } else {
                    // 会員情報更新系画面から遷移の場合
                    await router.push({ name: returnUrlRef.value });
                }

            } catch (e) {
                await router.push({ name: routeNames.GENERAL_ERROR, params: { message: "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。" } });
            }
        };

        onMounted((): void => {
            returnUrlRef.value = route.query.retUrl as string;
            errorMsgRef.value = props.message as string;
        });
        
        return {
            isDeterringRef,
            asyncDeterringWrap,
            passwordRef,
            disableAutoLoginRef,
            errorMsgRef,
            PASSWORD_MAX_LENGTH,
            passwordToggle,
            switchPasswordFieldType,
            login,
            linkageInfo,
            routeNames,
            returnUrlRef
        };
    },
});
