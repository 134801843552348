import { SidUserClient } from "@sid/sid_client";
import { BaseInfo, CommonAttrInfo, LinkageUserData, MailMagazineHpplusAcademia, ManageInfo, MemberAgreementInfo, ShippingInfo, SiteInfo, UserBase, UserHpplusAcademia } from "@/commons/interfaces";
import { InjectionKey, reactive } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import HapiacaProfile from "@/components/Linkage/Sites/HapiacaProfile.vue";
import HapiacaConfirm from "@/components/Linkage/Sites/HapiacaConfirm.vue";

// ハピアカ関連定数定義
/** 連携ID */
export const HAPIACA_LINKAGE_ID = 4;
/** 連携名称 */
export const HAPIACA_LINKAGE_NAME = "Hapiaca";
/** 連携パス */
export const HAPIACA_PATH = HAPIACA_LINKAGE_NAME.toLowerCase();
/** サイト固有情報APIパス */
export const HAPIACA_SITE_UNIQ_API_PATH = "/hpplus_academia/me";
/** デフォルトコールバックURL */
export const HAPIACA_DEFAULT_CALLBACK_URL = process.env.VUE_APP_HAPIACA_DEFAULT_CALLBACK_URL;
/** 許容コールバックURL */
export const HAPIACA_ACCEPT_CALLBACK_URLS = process.env.VUE_APP_HAPIACA_ACCEPT_CALLBACK_URLS.split(",").map((url: string) => `^${url}(\\?.*)?$`);
/** サービス規約URL */
export const HAPIACA_SERVICE_TERM_URL = process.env.VUE_APP_HAPIACA_SERVICE_TERM_URL;
/** メールアドレスドメイン */
export const HAPIACA_EMAIL_DOMAINS = [
    "mail.academia.hpplus.jp"
];

/** 基本属性情報入力 */
export const HAPIACA_PROFILE_COMPONENT = HapiacaProfile;
/** 基本属性情報確認 */
export const HAPIACA_CONFIRM_COMPONENT = HapiacaConfirm;

/**
 * ハピアカ連携画面用基本属性情報管理クラス
 */
export class HapiacaUserBase implements LinkageUserData<UserBase> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> = Symbol("HapiacaUserBase");

    /** 基本属性情報 */
    public readonly data: UserBase = reactive(new UserBase());
    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserBase());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserBase {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserBase): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.baseInfo = Object.assign(new BaseInfo(), obj.baseInfo);
        this.data.shippingInfo = obj.shippingInfo?.map(inf => Object.assign(new ShippingInfo(), inf)) || [];
        this.data.siteInfo = obj.siteInfo?.map(inf => Object.assign(new SiteInfo(), inf)) || [];
        this.data.commonAttrInfo = Object.assign(new CommonAttrInfo(), obj.commonAttrInfo);
        this.data.memberAgreeInfo = Object.assign(new MemberAgreementInfo(), obj.memberAgreeInfo);
        this.data.manageInfo = Object.assign(new ManageInfo(), obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.getUserAttr()
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserBase();   
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // 基本属性情報の有無
        if (!this.data.identityId) return false;
        // 登録者情報
        if (!this.data.baseInfo.firstName) return false;
        if (!this.data.baseInfo.lastName) return false;
        if (!this.data.baseInfo.firstKana) return false;
        if (!this.data.baseInfo.lastKana) return false;
        if (!this.data.baseInfo.zipCode) return false;
        if (!this.data.baseInfo.prefecture) return false;
        if (!this.data.baseInfo.address) return false;
        // if (!this.data.baseInfo.building) return false;
        if (!this.data.baseInfo.email) return false;
        if (!this.data.baseInfo.tel) return false;
        // 属性情報
        if (!this.data.commonAttrInfo.gender) return false;
        if (!this.data.commonAttrInfo.birthday) return false;
        // if (!this.data.commonAttrInfo.imagePath) return false;
        if (!this.data.commonAttrInfo.nickName) return false;
        // if (this.data.commonAttrInfo.isMarried === null || this.data.commonAttrInfo.isMarried === undefined) return false;
        // if (this.data.commonAttrInfo.hasChild === null || this.data.commonAttrInfo.hasChild === undefined) return false;
        // if (!this.data.commonAttrInfo.bloodType) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> {
        return HapiacaUserBase.injectionKey;
    }

}

/**
 * ハピアカ連携画面用サイト固有情報管理クラス
 */
export class HapiacaSiteUniq implements LinkageUserData<UserHpplusAcademia> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserHpplusAcademia>>> = Symbol("UserHpplusAcademia");

    /** ハピアカサイト固有情報 */
    public readonly data: UserHpplusAcademia = reactive(new UserHpplusAcademia());

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserHpplusAcademia());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserHpplusAcademia {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserHpplusAcademia): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.job = obj.job;
        this.data.interest = obj.interest;
        if (obj.mailMagazine && obj.mailMagazine.main !== null) {
            this.data.mailMagazine = Object.assign({}, obj.mailMagazine);
        } else {
            // メルマガ登録情報が未設定の場合はデフォルト値を設定
            this.data.mailMagazine = new MailMagazineHpplusAcademia();
        }
        this.data.manageInfo = Object.assign({}, obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.requestApi({ method: "GET", path: HAPIACA_SITE_UNIQ_API_PATH })
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserHpplusAcademia();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の有無
        if (!this.data.mailMagazine) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserHpplusAcademia>>> {
        return HapiacaSiteUniq.injectionKey;
    }
}
