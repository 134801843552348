
import { defineComponent, ref } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";


export default defineComponent({
    name: "LinkageGeneralError",
    props: {
        message: { type: String },
    },
    setup(props) {
        const { linkageInfo, siteDefaultCallbackUrl } = LinkageItem.inject();

        const messageRef = ref(props.message);

        if (props.message) {
            sessionStorage[`${linkageInfo.linkageName}ErrorMessage`] = messageRef.value;
        } else {
            messageRef.value = sessionStorage[`${linkageInfo.linkageName}ErrorMessage`];
        }

        return {
            messageRef,
            siteDefaultCallbackUrl
        };
    }
});
