import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02f9b60a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ha-signup-profile l-container" }
const _hoisted_2 = { class: "ha__ttl c-ttl" }
const _hoisted_3 = { class: "ha__sec ha-signup-profile__sec" }
const _hoisted_4 = { class: "ha-signup-profile__field-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}