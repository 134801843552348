
import { injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import { ImageItemKey, createImageItem } from "@/modules/imageInfo";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { defineComponent, provide } from "vue";

export default defineComponent({
    name: "LinkageLoginProfileParent",
    setup() {
        const { linkageInfo } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        
        // プロフィール画像項目の有無に関係なく、provide()を行う
        const imageItem = createImageItem();
        provide(ImageItemKey, imageItem);
        imageItem.image.path = userBaseItem.userData.commonAttrInfo.imagePath;
        return {};
    }
});
