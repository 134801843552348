
import { computed, defineComponent } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isSiteRegistered } from "@/commons/commonUtility";
import { injectUserBaseItem } from "@/commons/linkage/linkageCommon";

export default defineComponent({
    name: "LinkageLoginProfileConfirm",
    setup() {
        const { linkageInfo } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);

        // タイトル文言判定
        const isRegistered = computed(() => userBaseItem.userData.identityId && isSiteRegistered(linkageInfo.linkageId, userBaseItem.userData));
        const firstServiceReg = userBaseItem.userData.identityId && !isSiteRegistered(linkageInfo.linkageId, userBaseItem.userData);
        const title = firstServiceReg ? "会員情報の追加" : (isRegistered.value ? "会員情報更新" : "会員情報登録");

        return {
            title,
        };
    }
});
