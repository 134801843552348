/**
 * セッションストレージ利用可否確認
 */
export function availableSessionStorage() {
    try {
        sessionStorage.setItem("__storage_test__", "__storage_test__");
        sessionStorage.removeItem("__storage_test__");
        return true;
    } catch (e) {
        return false;
    }
}