import { ref } from "vue";

export default function () {
    const isDeterringRef = ref(false);
    const isReleaseFlagRef = ref(true);
    
    /**
     * 抑止開始
     */
    const startDeterring = () => {
        isDeterringRef.value = true;
    };

    /**
     * 抑止終了
     */
    const endDeterring = () => {
        isDeterringRef.value = false;
    };

    /**
     * 抑止未解放設定
     */
    const setNotRelease = () => {
        isReleaseFlagRef.value = false;
    };

    /**
     * 同期関数用抑止制御
     * @param proc 同期関数
     */
    const syncDeterringWrap = (proc: (...procArgs: any) => any, context: any, ...args: any) => {
        // 抑止中ならスキップ
        if (isDeterringRef.value) return;

        try {
            startDeterring();
            proc.apply(context, args);
        } finally {
            if (isReleaseFlagRef.value) {
                endDeterring();
            }
        }
    };

    /**
     * 非同期関数用抑止制御
     * @param proc 非同期関数
     */
    const asyncDeterringWrap = async (proc: (...procArgs: any) => Promise<any>, context: any, ...args: any) => {
        // 抑止中ならスキップ
        if (isDeterringRef.value) return;

        try {
            startDeterring();
            await proc.apply(context, args);
        } finally {
            if (isReleaseFlagRef.value) {
                endDeterring();
            }
        }
    };

    return {
        isDeterringRef,
        startDeterring,
        endDeterring,
        setNotRelease,
        syncDeterringWrap,
        asyncDeterringWrap        
    };
}