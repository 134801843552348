
import { defineComponent, ref } from "vue";
import LinkageSignupProgress from "@/components/Linkage/LinkageSignupProgress.vue";

export default defineComponent({
    name: "LinkageSignupProfile",
    components: {
        LinkageSignupProgress,
    },
    props: {
        message: { type: String },
    },
    setup(props) {
        const errorMsgRef = ref(props.message);

        return {
            errorMsgRef,
        };
    }
});
