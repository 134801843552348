
import { defineComponent } from "vue";

export default defineComponent({
    name: "LinkageSignupProgress",
    props: ["signupProgress"],
    setup() {
        return {};
    },
});
