import { SidUserClient } from "@sid/sid_client";
import { InjectionKey, reactive } from "vue";
import { LinkageUserData, UserAttribute } from "@/commons/interfaces";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";

/**
 * サイト固有情報未定義用のユーザデータ管理クラス
 */
export class NotExistSiteUniq implements LinkageUserData<UserAttribute> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserAttribute>>> = Symbol("NotExistSiteUniq");

    /** サイト固有情報 */
    public readonly data: UserAttribute = reactive({} as UserAttribute);

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        // do nothing
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserAttribute {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public copy(obj: UserAttribute): void {
        // do nothing
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        // do nothing
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の必須チェック
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserAttribute>>> {
        return NotExistSiteUniq.injectionKey;
    }
}
