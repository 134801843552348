import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "l-page" }
const _hoisted_2 = {
  key: 0,
  class: "p-mypage l-container has-side"
}
const _hoisted_3 = {
  key: 0,
  class: "p-mypage__side l-side"
}
const _hoisted_4 = { class: "p-mypage__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.waitViewRef)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isDispSideMenu)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_SideMenu)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["p-mypage__main", [_ctx.isDispSideMenu ? 'l-main' : 'l-main-no-side']])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_view)
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}