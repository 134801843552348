
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { loginStatus } from "@/modules/loginStatus";

export default defineComponent({
    name: "Login",
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        /* Reactive */
        const emailRef = ref("");
        const passwordRef = ref("");
        const disableAutoLoginRef = ref(false);
        const autoLoginRef = ref(true);
        const errorMsgRef = ref("");
        const returnUrlRef = ref("");

        const passwordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean } 
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * ログイン
         * @async
         */
        const login = async () => {
            try {
                // 念の為サインアウト
                await sidClient.signOut();
                loginStatus.value = false;

                // 自動ログイン無効化判断
                if (disableAutoLoginRef.value) {
                    autoLoginRef.value = false;
                }

                // サインイン
                try {
                    const result = await sidClient.signIn(emailRef.value, passwordRef.value, autoLoginRef.value);
                    if (result === "NEW_PASSWORD_REQUIRED") {
                        // 管理画面でアカウント登録された場合、または、管理画面でパスワード変更を行った場合
                        await router.push({ name: "NewPassword" });
                        return;
                    } else if (result === "RESET_PASSWORD_REQUIRED") {
                        // Cognitoでパスワード変更された場合
                        await router.push({ name: "SetPassword", query: { email: emailRef.value } });
                        return;
                    }
                } catch (e) {
                    if (e.code == "UserNotConfirmedException") {
                        // 実店舗ユーザ（ユーザ名でログイン）の場合のみだが、
                        // 実店舗ユーザは必ず確認済みのステータスとなる仕様のため、デッドコード
                        errorMsgRef.value = "ログインできませんでした。";
                    } else {
                        errorMsgRef.value = "ログインできませんでした。メールアドレスとパスワードをご確認ください。";
                    }
                    return;
                }
                
                // 基本属性情報取得
                await store.dispatch("fetchUserInfo");
                
                if (returnUrlRef.value) {
                    router.push({ name: returnUrlRef.value });
                } else {
                    router.push({ name: "MypageTop" });
                }
                
            } catch (e) {
                errorMsgRef.value = "エラーが発生しました。お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
            }
        };

        onMounted((): void => {
            returnUrlRef.value = route.query.retUrl as string;
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            emailRef,
            passwordRef,
            disableAutoLoginRef,
            errorMsgRef,
            PASSWORD_MAX_LENGTH,
            passwordToggle,
            switchPasswordFieldType,
            login
        };
    },
});
