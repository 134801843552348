
import { defineComponent, nextTick, onMounted, ref } from "vue";

export default defineComponent({
    name: "ProcedureMenu",
    setup() {
        const isOnRef = ref(false);

        onMounted(() => {
            nextTick(() => {
                isOnRef.value = true;
            });
        });

        return {
            isOnRef
        };
    },
});
