import * as Yup from "yup";
import { PASSWORD_MIN_LENGTH, PASSWORD_REGULAR_EXPRESSION } from "@/commons/constants";
import moment from "moment";

/* メモ */
/* このファイルでは、共通（サイト固有情報以外）のチェック内容を定義する */
/* サービスによって必須項目が異なるため、基本属性情報は必須版と任意版の両方を用意する */
/* 明示的にnullを認めている場合、必須項目だとしても.nullable()を付与する */

/* パスワード */
export const checkPassword = Yup.string()
    .matches(PASSWORD_REGULAR_EXPRESSION, "パスワードは、英字大文字、英字小文字、数字いずれも含む" + PASSWORD_MIN_LENGTH + "文字以上のパスワードを設定してください。")
    .required("パスワードは、必須項目です。");

export const checkPasswordRetype = Yup.string()
    .oneOf([Yup.ref("password"), null], "パスワードとパスワード確認が一致しません。")
    .required("パスワード確認は、必須項目です。");

export const checkOldPassword = Yup.string()
    .matches(PASSWORD_REGULAR_EXPRESSION, "現在のパスワードは、英字大文字、英字小文字、数字いずれも含む" + PASSWORD_MIN_LENGTH + "文字以上のパスワードを設定してください。")
    .required("現在のパスワードは、必須項目です。");

export const checkNewPassword = Yup.string()
    .matches(PASSWORD_REGULAR_EXPRESSION, "新しいパスワードは、英字大文字、英字小文字、数字いずれも含む" + PASSWORD_MIN_LENGTH + "文字以上のパスワードを設定してください。")
    .required("新しいパスワードは、必須項目です。");

export const checkNewPasswordRetype = Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "新しいパスワードと新しいパスワード確認が一致しません。")
    .required("新しいパスワード確認は、必須項目です。");

/* メールアドレス */
export const checkEmail = Yup.string()
    .email("メールアドレス形式に誤りがあります。")
    .max(255, "メールアドレスは、255文字以内で入力してください。")
    .required("メールアドレスは、必須項目です。");

export const checkEmailRetype = Yup.string()
    .oneOf([Yup.ref("email"), null], "メールアドレスとメールアドレス確認が一致しません。")
    .required("メールアドレス確認は、必須項目です。");

export const checkNewEmail = Yup.string()
    .email("メールアドレス形式に誤りがあります。")
    .max(255, "255文字以内で入力してください。")
    .required("新しいメールアドレスは、必須項目です。");

export const checkNewEmailRetype = Yup.string()
    .oneOf([Yup.ref("newEmail"), null], "新しいメールアドレスと新しいメールアドレス確認が一致しません。")
    .required("新しいメールアドレス確認は、必須項目です。");

/* 確認コード */
export const checkConfirmationCode = Yup.string()
    .required("メールアドレスに届いた確認コードを入力してください。");

/* 規約 */
/* oneOfだけだと、ブラウザバックして戻ってきた場合、undefinedとなるため、効かなくなる */
export const checkAgreement = Yup.boolean()
    .required("同意が必要です。")
    .oneOf([true], "同意が必要です。");

/* 基本属性情報（必須） */
export const checkLastNameIsReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "お名前（姓）に < と > は使用できません。")
    .required("お名前（姓）は、必須項目です。");

export const checkFirstNameIsReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "お名前（名）に < と > は使用できません。")
    .required("お名前（名）は、必須項目です。");

export const checkLastKanaIsReqd = Yup.string()
    .nullable()
    .matches(/^[ぁ-んァ-ンヴー \t]*$/, "ふりがな（せい）は「ひらがな」もしくは「カタカナ」で入力してください。")
    .required("ふりがな（せい）は、必須項目です。");

export const checkFirstKanaIsReqd = Yup.string()
    .nullable()
    .matches(/^[ぁ-んァ-ンヴー \t]*$/, "ふりがな（めい）は「ひらがな」もしくは「カタカナ」で入力してください。")
    .required("ふりがな（めい）は、必須項目です。");

export const checkZipCodeIsReqd = Yup.string()
    .nullable()
    .matches(/^(\d{7})$/, "郵便番号は、ハイフンなし半角数字7桁で入力してください。例）0000000")
    .required("郵便番号は、必須項目です。");

export const checkPrefectureIsReqd = Yup.string()
    .nullable()
    .max(32, "都道府県は、32文字以内で入力してください。")
    .required("都道府県は、必須項目です。");

export const checkAddressIsReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "住所（市区町村番地）に < と > は使用できません。")
    .max(32, "住所（市区町村番地）は、32文字以内で入力してください。")
    .required("住所（市区町村番地）は、必須項目です。");

export const checkBuildingIsReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "住所（建物（階数, 部屋番号））に < と > は使用できません。")
    .max(32, "住所（建物（階数, 部屋番号））は、32文字以内で入力してください。")
    .required("住所（建物（階数, 部屋番号））は、必須項目です。");

export const checkTelIsReqd = Yup.string()
    .nullable()
    .matches(/^(0{1}\d{9,10})?$/, "電話番号は、ハイフンなし10桁または11桁の半角数字で入力してください。例）00012345678")
    .required("電話番号は、必須項目です。");
   
export const checkGenderIsReqd = Yup.string()
    .nullable()
    .required("性別は、必須項目です。");

export const checkBirthdayIsReqd = Yup.string()
    .transform(value => (value === "" ? null : value))
    .nullable()
    .test(
        "future",
        "誕生日が正しくありません。",
        (value) => {
            const inputday = moment(value, "YYYY-M-D", true);
            const today = moment();
            // 形式不備の場合はtrueとしてチェックを通す
            return inputday.isValid() ? inputday.isSameOrBefore(today, "days") : true;
        }
    )
    .test(
        "format",
        "誕生日に入力不備があります。",
        (value) => {
            const inputday = moment(value, "YYYY-M-D", true);
            // nullの場合はtrueとしてチェックを通す
            return value !== null ? inputday.isValid() : true;
        }
    )
    .required("誕生日は、必須項目です。");

export const checkNicknameIsReqd = Yup.string()
    .nullable()
    .max(20, "ニックネームは、20文字以内で入力してください。")
    .required("ニックネームは、必須項目です。");

export const checkIsMarriedIsReqd = Yup.boolean()
    .nullable()
    .required("結婚の有無は、必須項目です。");

export const checkHasChildIsReqd = Yup.boolean()
    .nullable()
    .required("子供の有無は、必須項目です。");

export const checkBloodTypeIsReqd = Yup.string()
    .nullable()
    .required("血液型は、必須項目です。");

/* 基本属性情報（任意） */
export const checkLastNameNotReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "お名前（姓）に < と > は使用できません。");

export const checkFirstNameNotReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "お名前（名）に < と > は使用できません。");

export const checkLastKanaNotReqd = Yup.string()
    .nullable()
    .matches(/^[ぁ-んァ-ンヴー \t]*$/, "ふりがな（せい）は「ひらがな」もしくは「カタカナ」で入力してください。");

export const checkFirstKanaNotReqd = Yup.string()
    .nullable()
    .matches(/^[ぁ-んァ-ンヴー \t]*$/, "ふりがな（めい）は「ひらがな」もしくは「カタカナ」で入力してください。");

export const checkZipCodeNotReqd = Yup.string()
    .nullable()
    .matches(/^(\d{7})?$/, "郵便番号は、ハイフンなし半角数字7桁で入力してください。例）0000000");

export const checkPrefectureNotReqd = Yup.string()
    .nullable()
    .max(32, "都道府県は、32文字以内で入力してください。");

export const checkAddressNotReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "住所（市区町村番地）に < と > は使用できません。")
    .max(32, "住所（市区町村番地）は、32文字以内で入力してください。");

export const checkBuildingNotReqd = Yup.string()
    .nullable()
    .matches(/^[^<>]*$/, "住所（建物（階数, 部屋番号））に < と > は使用できません。")
    .max(32, "住所（建物（階数, 部屋番号））は、32文字以内で入力してください。");

export const checkTelNotReqd = Yup.string()
    .nullable()
    .matches(/^(0{1}\d{9,10})?$/, "電話番号は、ハイフンなし10桁または11桁の半角数字で入力してください。例）00012345678");
   
export const checkGenderNotReqd = Yup.string()
    .nullable();

export const checkBirthdayNotReqd = Yup.string()
    .transform(value => (value === "" ? null : value))
    .nullable()
    .test(
        "future",
        "誕生日が正しくありません。",
        (value) => {
            const inputday = moment(value, "YYYY-M-D", true);
            const today = moment();
            // 形式不備の場合はtrueとしてチェックを通す
            return inputday.isValid() ? inputday.isSameOrBefore(today, "days") : true;
        }
    )
    .test(
        "format",
        "誕生日に入力不備があります。",
        (value) => {
            const inputday = moment(value, "YYYY-M-D", true);
            // nullの場合はtrueとしてチェックを通す
            return value !== null ? inputday.isValid() : true;
        }
    );

export const checkNicknameNotReqd = Yup.string()
    .nullable()
    .max(20, "ニックネームは、20文字以内で入力してください。");

export const checkIsMarriedNotReqd = Yup.boolean()
    .nullable();

export const checkHasChildNotReqd = Yup.boolean()
    .nullable();

export const checkBloodTypeNotReqd = Yup.string()
    .nullable();
