
import { defineComponent, SetupContext } from "vue";
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import { loginStatus } from "@/modules/loginStatus";

export default defineComponent({
    name: "SideMenu",
    setup(props: any, context: SetupContext) {
        const sidClient = useSidClient();
        const router = useRouter();

        const logout = async() => {
            await sidClient.signOut();
            loginStatus.value = false;
            context.emit("change-isActive", false);
            router.push({ name: "Top" });
        };

        return {
            logout
        };
    },
});
