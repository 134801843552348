import { SID_ERROR_MSG_NOT_AUTHENTICATED, SID_ERROR_MSG_UNAUTHORIZED } from "@sid/sid_client";
import { SiteLinkage, UserBase } from "@/commons/interfaces";
import { useStore } from "@/store";
import { computed } from "vue";

/**
 * 認証エラー判定
 * @param error エラーオブジェクト
 */
export function isAuthFailed(error: Error) {

    if (error.message === SID_ERROR_MSG_NOT_AUTHENTICATED || error.message === SID_ERROR_MSG_UNAUTHORIZED) {
        return true;
    }
    return false;
}

/**
 * サイト登録有無判定
 */
export function isSiteRegistered(linkageId: number, userBase: UserBase | null) {
    return userBase?.siteInfo?.filter((site: any) => site.enable && site.siteLinkageId === linkageId).length;
}

/**
 * ユーザのサイト登録状況取得
 * @param siteLinkage サイト連携情報リスト
 * @param userInfo ユーザ
 */
export function getSiteRegistration (siteLinkage: SiteLinkage[], userInfo: UserBase) {
    // 登録判定
    const isRegister = (linkId: number | null) => (userInfo.siteInfo || []).filter(userSite => userSite.enable && userSite.siteLinkageId == linkId).length > 0;
    // 登録サイト一覧
    const registeredSiteList = computed(() => siteLinkage.filter(s => isRegister(s.link_id)));
    // 未登録サイト一覧
    const notRegisteredSiteList = computed(() => siteLinkage.filter(s => !isRegister(s.link_id)));

    return {
        registeredSiteList,
        notRegisteredSiteList
    };
}

/**
 * サービス名取得
 * @param linkageId 連携ID
 */
export function getServiceName(linkageId: number) {
    const store = useStore();
    return store.state.siteLinkage.find((site: any) => site.link_id === linkageId)?.name;
}

/**
 * URIコンポーネント再エンコード
 * @param str コンポーネント文字列
 */
export function reEncodeUriComponent(str: string) {
    return encodeURIComponent(decodeURIComponent(str));
}

/**
 * string型からboolean型へ型変換
 */
export function toBoolean(booleanStr: string | null): boolean {
    if (booleanStr === null)
        return false;
    // "true"文字列と比較した結果を返す
    // 念のため小文字化しておく
    return booleanStr.toLowerCase() === "true";
}