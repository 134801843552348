/**
 * ファイル読み込み
 * @param file 
 * @returns 
 */
export async function readFile(file: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(new Error("File read error"));
        };
        reader.onabort = () => {
            reject(new Error("File read aborted"));
        };
        reader.readAsArrayBuffer(file);
    });
}

/**
 * 画像フォーマット判定
 * @param arrayBuffer 
 * @returns true or false
 */
export function checkImageFormat(arrayBuffer: ArrayBuffer) {
    const array = new Uint8Array(arrayBuffer).subarray(0, 4);
    let header = "";

    for (let i = 0; i < array.length; i++) {
        header += array[i].toString(16);
    }

    switch (true) {
    /* png */
        case /^89504e47/.test(header):
            return true;
        /* gif */
        case /^47494638/.test(header):
            return true;
        /* bmp */
        case /^424d/.test(header):
            return true;
        /* jpeg */
        case /^ffd8ff/.test(header):
            return true;
        default:
            return false;
    }
}

/**
 * 配列を指定した要素数に分割
 * @param ary 
 * @param size 
 * @returns 
 */
function chunk(ary: Uint8Array, size: number) {
    const chunkNum = Math.floor(ary.length / size) + (ary.length % size ? 1 : 0);
    return [...Array(chunkNum)].map((v, index) => ary.slice(index*size, (index+1)*size < ary.length? (index+1)*size: ary.length));
}

/**
 * arrayBuffer→Base64変換
 * @param binaryData 
 * @returns 
 */
export function arrayBufferToBase64(binaryData: any) {
    let binary = "";
    const bytes = new Uint8Array(binaryData);
    const size = 10240;
    const splitBytes = chunk(bytes, size);
    for (let i = 0; i < splitBytes.length; i++) {
        binary += String.fromCharCode(...splitBytes[i]);
    }
    return btoa(binary);
}