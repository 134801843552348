import { InjectionKey, reactive } from "vue";

export const createCognitoItem = () => {
    const cognitoInfo = reactive<{
        userName: string;
        password: string;
        email: string;
        tel: string;
    }>({
        userName: "",
        password: "",
        email: "",
        tel: ""
    });

    return {
        cognitoInfo
    };
};

export type CognitoItem = ReturnType<typeof createCognitoItem>

export const CognitoItemKey: InjectionKey<CognitoItem> = Symbol("CognitoItem");