
import { defineComponent, onMounted, onUnmounted, provide } from "vue";
import { ContactFormInfoKey, createContactFormInfo } from "@/modules/contactForm";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
    name: "ContactParent",
    setup() {
        // 問い合わせフォームデータ作成
        const contactFormInfo = createContactFormInfo();
        provide(ContactFormInfoKey, contactFormInfo);

        // reCAPTCHAバッジ表示
        const { recaptchaLoaded, instance } = useReCaptcha() as any;
        onMounted(async () => {
            await recaptchaLoaded();
            instance.value.showBadge();
        });
        onUnmounted(async () => {
            await recaptchaLoaded();
            instance.value.hideBadge();
        });
    },
});
