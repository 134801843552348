
import { defineComponent, provide } from "vue";
import { CognitoItemKey, createCognitoItem } from "@/modules/cognitoItem";
import { createImageItem, ImageItemKey } from "@/modules/imageInfo";
import { UserBaseItem } from "@/modules/userBaseItem";

export default defineComponent({
    name: "SignupParent",
    setup() {
        // Cognito情報をprovide
        provide(CognitoItemKey, createCognitoItem());
        // 基本属性情報をprovide
        const userBaseItem = UserBaseItem.create();
        userBaseItem.provide();
        // プロフィール画像情報をprovide
        provide(ImageItemKey, createImageItem());
        return {};
    }
});
