
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { LinkageItem } from "@/modules/linkage/linkageInfo";

type SiteLogoInfo = {
    url: string;
    logoUrl: string;
    altName: string;
};

export default defineComponent({
    name: "LinkageHeader",
    setup() {
        const store = useStore();
        const { linkageInfo, siteDefaultCallbackUrl } = LinkageItem.inject();
        const logoImageBaseURL = process.env.VUE_APP_BASE_URL + "/img/mypage/";
        const siteInfo = store.state.siteLinkage.find((site: any) => site.link_id === linkageInfo.linkageId)!;

        let siteLogoInfos: SiteLogoInfo[];
        if (!siteInfo.children) {
            // 1サービス1サイトの場合
            siteLogoInfos = [{ url: siteInfo.url, logoUrl: `${logoImageBaseURL}logo_${siteInfo.link_id}.svg`, altName: siteInfo.name }];
        } else {
            // 1サービス複数サイトの場合
            siteLogoInfos = siteInfo.children.map((child: any) => ({
                url: child.url,
                logoUrl: `${logoImageBaseURL}logo_${siteInfo.link_id}_${child.linkage_sub_id}.svg`,
                altName: child.name
            }));
        }

        return {
            store,
            linkageInfo,
            siteLogoInfos,
        };
    },
});
