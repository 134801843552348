
import { defineComponent, inject, onMounted, ref } from "vue";
import { ContactFormInfo, ContactFormInfoKey } from "@/modules/contactForm";
import { useRouter } from "vue-router";
import { useSidClient } from "@/plugins/auth";
import { useReCaptcha } from "vue-recaptcha-v3";
import { AxiosResponse } from "axios";
import useDeterring from "@/commons/useDeterring";

export default defineComponent({
    name: "ContactConfirm",
    setup() {
        const router = useRouter();
        const sidClient = useSidClient();
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() as any;
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        // 問い合わせフォームデータ取得
        const contactForm = inject(ContactFormInfoKey) as ContactFormInfo;
        const contactFormItems = contactForm.contactFormItems;

        const errorMsgRef = ref("");

        // フォームデータをロード
        onMounted(() => {
            contactForm.loadStorage();
            if (contactForm.isEmpty()) {
                router.push({ name: "Contact" });
                return;
            }
        });

        // 確認画面遷移
        const send = async () => {
            try {
                // reCAPTCHAロード待ち
                await recaptchaLoaded();
                // reCAPTCHA認証
                const token = await executeRecaptcha("login");

                // 問い合わせ送信
                const response = await sidClient.requestNoneAuthApi({
                    path: "/support/contact",
                    method: "POST",
                    body: {
                        lastName: contactFormItems.lastName,
                        firstName: contactFormItems.firstName,
                        lastKana: contactFormItems.lastKana,
                        firstKana: contactFormItems.firstKana,
                        email: contactFormItems.email,
                        service: contactFormItems.service,
                        subject: contactFormItems.subject,
                        detail: contactFormItems.detail.replaceAll("<","&lt;").replaceAll(">","&gt;"),
                        token: token
                    }
                }).catch(err => err.response) as AxiosResponse;
                // 結果確認
                if (response.status !== 200) {
                    if (response.data === "invalid reCAPTCHA.") {
                        errorMsgRef.value = "reCAPTCHAによる検証確認が取れませんでした。";
                    } else {
                        errorMsgRef.value = "問い合わせ送信に失敗しました。後ほど再度お試し下さい。";
                    }
                    return;
                }
                // フォームデータをクリア
                contactForm.clear();
                // 画面遷移
                router.push({ name: "ContactComplete" });
            } catch (e) {
                console.error(e);
                errorMsgRef.value = "問い合わせ送信に失敗しました。後ほど再度お試し下さい。";
            }
        };
        const cancel = () => {
            router.push({ name: "Contact" });
        };

        return {
            isDeterringRef,
            asyncDeterringWrap,
            contactFormItems,
            errorMsgRef,
            send,
            cancel,
        };
    },
});
