import { InjectionKey, reactive } from "vue";
import { availableSessionStorage } from "@/commons/webStorageUtility";

export const createContactFormInfo = () => {
    // フォームデータ
    const contactFormItems = reactive({
        lastName: "",
        firstName: "",
        lastKana: "",
        firstKana: "",
        email: "",
        service: "",
        subject: "",
        detail: "",
    });

    // セッションストレージ格納
    const storeStorage = () => {
        if (!availableSessionStorage()) {
            return;
        }
        sessionStorage["contactForm.lastName"] = contactFormItems.lastName;
        sessionStorage["contactForm.firstName"] = contactFormItems.firstName;
        sessionStorage["contactForm.lastKana"] = contactFormItems.lastKana;
        sessionStorage["contactForm.firstKana"] = contactFormItems.firstKana;
        sessionStorage["contactForm.email"] = contactFormItems.email;
        sessionStorage["contactForm.service"] = contactFormItems.service;
        sessionStorage["contactForm.subject"] = contactFormItems.subject;
        sessionStorage["contactForm.detail"] = contactFormItems.detail;
    };

    // セッションストレージから取得
    const loadStorage = () => {
        if (!availableSessionStorage()) {
            return;
        }
        contactFormItems.lastName = sessionStorage["contactForm.lastName"] || "";
        contactFormItems.firstName = sessionStorage["contactForm.firstName"] || "";
        contactFormItems.lastKana = sessionStorage["contactForm.lastKana"] || "";
        contactFormItems.firstKana = sessionStorage["contactForm.firstKana"] || "";
        contactFormItems.email = sessionStorage["contactForm.email"] || "";
        contactFormItems.service = sessionStorage["contactForm.service"] || "";
        contactFormItems.subject = sessionStorage["contactForm.subject"] || "";
        contactFormItems.detail = sessionStorage["contactForm.detail"] || "";
    };

    // フォームデータをクリア
    const clear = () => {
        contactFormItems.lastName = "";
        contactFormItems.firstName ="";
        contactFormItems.lastKana = "";
        contactFormItems.firstKana ="";
        contactFormItems.email = "";
        contactFormItems.service = "";
        contactFormItems.subject = "";
        contactFormItems.detail = "";
        // セッションストレージクリア
        if (!availableSessionStorage()) {
            return;
        }
        sessionStorage.removeItem("contactForm.lastName");
        sessionStorage.removeItem("contactForm.firstName");
        sessionStorage.removeItem("contactForm.lastKana");
        sessionStorage.removeItem("contactForm.firstKana");
        sessionStorage.removeItem("contactForm.email");
        sessionStorage.removeItem("contactForm.service");
        sessionStorage.removeItem("contactForm.subject");
        sessionStorage.removeItem("contactForm.detail");
    };

    // フォームデータ空判定
    const isEmpty = () => {
        for (const value of Object.values(contactFormItems)) {
            if (value) return false;
        }
        return true;
    };

    return {
        contactFormItems,
        storeStorage,
        loadStorage,
        clear,
        isEmpty
    };
};

export type ContactFormInfo = ReturnType<typeof createContactFormInfo>
export const ContactFormInfoKey: InjectionKey<ContactFormInfo> = Symbol("ContactFormInfo");
