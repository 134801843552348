import { createStore, useStore as baseUseStore } from "vuex";
import { BaseInfo, CommonAttrInfo, ManageInfo, MemberAgreementInfo, UserBase, SiteLinkage } from "@/commons/interfaces";
import { sidClient } from "@/plugins/auth";

export const key = Symbol();

export const store = createStore({
    state: {
        userInfo: {
            identityId: null,
            userName: null,
            baseInfo: new BaseInfo(),
            shippingInfo: [],
            siteInfo: [],
            commonAttrInfo: new CommonAttrInfo(),
            memberAgreeInfo: new MemberAgreementInfo(),
            manageInfo: new ManageInfo()
        } as UserBase,
        siteLinkage: [new SiteLinkage()]
    },
    mutations: {
        setUserInfo(state, data: UserBase) {
            state.userInfo.identityId = data.identityId;
            state.userInfo.userName = data.userName;
            state.userInfo.baseInfo = Object.assign({
                lastName: "",
                firstName: "",
                lastKana: "",
                firstKana: "",
                zipCode: "",
                prefecture: "",
                address: "",
                building: "",
                email: "",
                tel: ""
            }, data.baseInfo);
            state.userInfo.shippingInfo = data.shippingInfo?.map(inf => Object.assign({}, inf)) || [];
            state.userInfo.siteInfo = data.siteInfo?.map(inf => Object.assign({}, inf)) || [];
            state.userInfo.commonAttrInfo = Object.assign({
                gender: null,
                birthday: "",
                imagePath: "",
                nickName: "",
                isMarried: null,
                hasChild: null,
                bloodType: null
            }, data.commonAttrInfo);
            state.userInfo.memberAgreeInfo = Object.assign({}, data.memberAgreeInfo);
            state.userInfo.manageInfo = Object.assign({}, data.manageInfo);
        },
        clearUserInfo(state) {
            state.userInfo.identityId = null;
            state.userInfo.userName = null;
            state.userInfo.baseInfo = new BaseInfo();
            state.userInfo.shippingInfo = [];
            state.userInfo.siteInfo = [];
            state.userInfo.commonAttrInfo = new CommonAttrInfo();
            state.userInfo.memberAgreeInfo = new MemberAgreementInfo();
            state.userInfo.manageInfo = new ManageInfo();
        },
        setSiteLinkage(state, data: SiteLinkage[]){
            state.siteLinkage = data.sort((a,b) => (a.link_id as number) - (b.link_id as number))
                .map(inf => Object.assign({}, inf)) || [];   
        },
        clearSiteLinkage(state) {
            state.siteLinkage = [];
        }
    },
    actions: {
        async fetchUserInfo({ commit }) {
            await sidClient.getUserAttr()
                .then(result => {
                    const userInfo = result.data;
                    commit("setUserInfo", userInfo);
                })
                .catch(result => {
                    if (result.response?.status === 404) {
                        commit("clearUserInfo");
                        return;
                    } else {
                        throw new Error("Failed to get information.");
                    }
                });
        },
        resetUserInfo({ commit }) {
            commit("clearUserInfo");
        },
        async fetchSiteLinkage({ commit }) {
            await sidClient.requestNoneAuthApi({
                baseUrl: process.env.VUE_APP_ADMIN_API_BASE_URL,
                path: "/site_linkage/list",
                method: "POST"
            }).then(result => {
                commit("setSiteLinkage", result.data);
            }).catch(() => {
                commit("clearSiteLinkage");
                return;
            });
        }
    },
    modules: {}
});

export function useStore() {
    return baseUseStore(key);
}