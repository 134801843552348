import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkageHeader = _resolveComponent("LinkageHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LinkageFooter = _resolveComponent("LinkageFooter")!

  return (_ctx.waitViewRef)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_LinkageHeader),
        _createVNode(_component_router_view),
        _createVNode(_component_LinkageFooter)
      ]))
    : _createCommentVNode("", true)
}