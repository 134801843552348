
import { defineComponent, onMounted, ref, nextTick, Ref, onUnmounted } from "vue";
import ProcedureMenu from "@/components/Portal/ProcedureMenu.vue";
import { loginStatus } from "@/modules/loginStatus";

export default defineComponent({
    name: "Top",
    components: {
        ProcedureMenu,
    },
    setup() {
        const isOnRef = ref(false);
        const isLoginHoverRef = ref(false);
        const isSignupHoverRef = ref(false);
        const jsScrollfadeUp1Ref = ref<HTMLElement | undefined>();
        const isScrollOn1Ref = ref(false);

        /**
         * Scroll animation
         * @param scrollElement Ref<HTMLElement>
         */
        const scrollAnimation = (scrollElement: Ref<HTMLElement | undefined>, isScrollOnRef: Ref<boolean>) => {
            //ブラウザ全体のスクロール位置を取得
            const scrollPos = window.scrollY;
            //ブラウザ全体の高さを取得
            const winHeight = window.innerHeight;
            //要素のtopからの位置を取得
            const target = scrollElement.value;
            if (!target) {
                return undefined;
            }
            const targetPos = target.getBoundingClientRect().top;

            if (scrollPos > targetPos - winHeight + 100) {
                isScrollOnRef.value = true;
            }
        };

        onMounted(() => {
            nextTick(() => {
                isOnRef.value = true;
            });

            window.addEventListener("scroll", function () { scrollAnimation(jsScrollfadeUp1Ref, isScrollOn1Ref); });
        });

        onUnmounted(() => {
            window.removeEventListener("scroll", function () { scrollAnimation(jsScrollfadeUp1Ref, isScrollOn1Ref); });
        });

        return {
            isOnRef,            
            isLoginHoverRef,
            isSignupHoverRef,
            jsScrollfadeUp1Ref,
            isScrollOn1Ref,
            loginStatus
        };
    },
});
