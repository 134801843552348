
import { defineComponent, onMounted, provide, ref } from "vue";
import { useStore } from "@/store";
import { UserBaseItem } from "@/modules/userBaseItem";
import { LeaveNoteItemKey, createLeaveNoteItem } from "@/modules/leaveNote";

export default defineComponent({
    name:"WithdrawalParent",
    setup() {
        const store = useStore();
        
        const userBaseItem = UserBaseItem.inject();
        const isMounted = ref(false);

        // 退会理由をprovide
        provide(LeaveNoteItemKey, createLeaveNoteItem());

        onMounted(async () => {
            try {
                // 基本属性情報再取得して保持
                await store.dispatch("fetchUserInfo");
                userBaseItem.setUserInfo(store.state.userInfo);
            } catch (e) {
                // 特に何もしない
            }
            // 子コンポーネントのonMountedが先に動くので、抑止
            isMounted.value = true;
        });

        return {
            isMounted
        };
    },
});
