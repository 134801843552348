import { createRouter, createWebHistory, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw } from "vue-router";
import CommonEmptyParent from "@/components/CommonEmptyParent.vue";
import Top from "@/views/Portal/Top.vue";
import Login from "@/views/Portal/Auth/Login.vue";
import SignupParent from "@/views/Portal/Signup/SignupParent.vue";
import Signup from "@/views/Portal/Signup/Signup.vue";
import SignupCode from "@/views/Portal/Signup/SignupCode.vue";
import SignupProfile from "@/views/Portal/Signup/SignupProfile.vue";
import SignupConfirm from "@/views/Portal/Signup/SignupConfirm.vue";
import NewPassword from "@/views/Portal/Auth/NewPassword.vue";
import ResetPassword from "@/views/Portal/Auth/ResetPassword.vue";
import SetPassword from "@/views/Portal/Auth/SetPassword.vue";
import Mypage from "@/views/Portal/Mypage/index.vue";
import MypageTop from "@/views/Portal/Mypage/MypageTop.vue";
import ProfileParent from "@/views/Portal/Mypage/ProfileParent.vue";
import Profile from "@/views/Portal/Mypage/Profile.vue";
import ProfileConfirm from "@/views/Portal/Mypage/ProfileConfirm.vue";
import ProfileComplete from "@/views/Portal/Mypage/ProfileComplete.vue";
import ChangePassword from "@/views/Portal/Mypage/ChangePassword.vue";
import ChangePasswordComplete from "@/views/Portal/Mypage/ChangePasswordComplete.vue";
import ChangeEmail from "@/views/Portal/Mypage/ChangeEmail.vue";
import ChangeEmailConfirm from "@/views/Portal/Mypage/ChangeEmailConfirm.vue";
import ChangeEmailComplete from "@/views/Portal/Mypage/ChangeEmailComplete.vue";
import WithdrawalParent from "@/views/Portal/Mypage/WithdrawalParent.vue";
import Withdrawal from "@/views/Portal/Mypage/Withdrawal.vue";
import WithdrawalConfirm from "@/views/Portal/Mypage/WithdrawalConfirm.vue";
import WithdrawalComplete from "@/views/Portal/WithdrawalComplete.vue";
import QuitService from "@/views/Portal/Mypage/QuitService.vue";
import Terms from "@/views/Portal/Terms.vue";
import Help from "@/views/Portal/Help.vue";
import ContactParent from "@/views/Portal/Contact/ContactParent.vue";
import Contact from "@/views/Portal/Contact/Contact.vue";
import ContactConfirm from "@/views/Portal/Contact/ContactConfirm.vue";
import ContactComplete from "@/views/Portal/Contact/ContactComplete.vue";
import { sidClient } from "@/plugins/auth";
import { loginStatus } from "@/modules/loginStatus";

// スクロール定義
const scrollBehavior = async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition: any) => {
    if (savedPosition) {
        return savedPosition;
    } else if (to.hash) {
        return { el: to.hash, behavior: "smooth" };
    } else {
        return { left: 0, top: 0 };
    }
};

// 共通のルート定義
export const commonRoute = [
    {
        path: "/terms",
        name: "Terms",
        component: Terms,
        meta: { title: "集英社ID会員規約" },
    },
    {
        path: "/help",
        name: "Help",
        component: Help,
        meta: { title: "よくあるご質問" },
    },
    {
        path: "/contact",
        name: "ContactParent",
        component: ContactParent,
        children: [
            {
                path: "",
                name: "Contact",
                component: Contact,
                meta: { title: "お問い合わせ" },
            },
            {
                path: "confirm",
                name: "ContactConfirm",
                component: ContactConfirm,
                meta: { title: "お問い合わせ(確認)" },
            },
            {
                path: "complete",
                name: "ContactComplete",
                component: ContactComplete,
                meta: { title: "お問い合わせ(完了)" },
            },
        ]
    },
];

// ポータル用ルート定義
const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Top",
        component: Top,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { title: "ログイン" },
        beforeEnter: (to, from, next) => {
            if (loginStatus.value) {
                // ログイン済み
                if (to.query.retUrl?.length) {
                    // 遷移先が指定されている場合はその画面へ
                    next({ name: to.query.retUrl as string });
                } else {
                    // マイページトップに遷移する
                    next({ name: "MypageTop" });
                }
            } else {
                next();
            }
        }
    },
    {
        path: "/signup",
        name: "SignupParent",
        component: SignupParent,
        children: [
            {
                path: "",
                name: "Signup",
                component: Signup,
                meta: { title: "新規会員登録" },
            },
            {
                path: "code",
                name: "SignupCode",
                component: SignupCode,
                meta: { title: "新規会員登録(確認コード入力)" },
            },
            {
                path: "code/:username",
                name: "SignupCodeFromLink",
                component: SignupCode,
                meta: { title: "新規会員登録(確認コード入力)" },
            },
            {
                path: "profile",
                name: "SignupProfile",
                component: SignupProfile,
                meta: { title: "新規会員登録(会員情報入力)" },
            },
            {
                path: "confirm",
                name: "SignupConfirm",
                component: SignupConfirm,
                meta: { title: "新規会員登録(会員情報確認)" },
                beforeEnter: (to, from, next) => {
                    if (!from.name) {
                        next({ name: "SignupProfile" });
                    } else {
                        next();
                    }
                },
            },
        ]
    },
    {
        path: "/new_password",
        name: "NewPassword",
        component: NewPassword,
        meta: { title: "新規パスワード設定" },
        beforeEnter: (to, from, next) => {
            if (!from.name) {
                next({ name: "Login" });
            } else {
                next();
            }
        },
    },
    {
        path: "/reset_password",
        name: "ResetPassword",
        component: ResetPassword,
        meta: { title: "パスワードリセット" },
    },
    {
        path: "/set_password",
        name: "SetPassword",
        component: SetPassword,
        meta: { title: "パスワード再設定" },
    },
    {
        path: "/withdrawal_complete",
        name: "WithdrawalComplete",
        component: WithdrawalComplete,
        meta: { title: "退会完了" },
    },
    {
        path: "/mypage",
        name: "Mypage",
        component: Mypage,
        meta: { isMemberOnly: true },
        children: [
            {
                path: "top",
                name: "MypageTop",
                component: MypageTop,
                meta: { title: "マイページ" },
            },
            {
                path: "profile",
                name: "ProfileParent",
                component: ProfileParent,
                children: [
                    {
                        path: "",
                        name: "Profile",
                        component: Profile,
                        meta: { title: "会員情報変更" },
                    },
                    {
                        path: "confirm",
                        name: "ProfileConfirm",
                        component: ProfileConfirm,
                        meta: { title: "会員情報変更(確認)" },
                        beforeEnter: (to, from, next) => {
                            if (!from.name) {
                                next({ name: "Profile" });
                            } else {
                                next();
                            }
                        },
                    },
                    {
                        path: "complete",
                        name: "ProfileComplete",
                        component: ProfileComplete,
                        meta: { title: "会員情報変更(完了)" },
                    },

                ]
            },
            {
                path: "change_password",
                name: "ChangePasswordParent",
                component: CommonEmptyParent,
                children: [
                    {
                        path: "",
                        name: "ChangePassword",
                        component: ChangePassword,
                        meta: { title: "パスワード変更" },
                    },
                    {
                        path: "complete",
                        name: "ChangePasswordComplete",
                        component: ChangePasswordComplete,
                        meta: { title: "パスワード変更(完了)" },
                    },
                ]
            },
            {
                path: "change_email",
                name: "ChangeEmailParent",
                component: CommonEmptyParent,
                children: [
                    {
                        path: "",
                        name: "ChangeEmail",
                        component: ChangeEmail,
                        meta: { title: "メールアドレス変更" },
                    },
                    {
                        path: "confirm",
                        name: "ChangeEmailConfirm",
                        component: ChangeEmailConfirm,
                        meta: { title: "メールアドレス変更(確認)" },
                        beforeEnter: (to, from, next) => {
                            if (!from.name) {
                                next({ name: "ChangeEmail" });
                            } else {
                                next();
                            }
                        },
                    },
                    {
                        path: "complete",
                        name: "ChangeEmailComplete",
                        component: ChangeEmailComplete,
                        meta: { title: "メールアドレス変更(完了)" },
                    },
                ]
            },
            {
                path: "withdrawal",
                name: "WithdrawalParent",
                component: WithdrawalParent,
                children: [
                    {
                        path: "",
                        name: "Withdrawal",
                        component: Withdrawal,
                        meta: { title: "退会" },
                    },
                    {
                        path: "confirm",
                        name: "WithdrawalConfirm",
                        component: WithdrawalConfirm,
                        meta: { title: "退会(確認)" },
                        beforeEnter: (to, from, next) => {
                            if (!from.name) {
                                next({ name: "Withdrawal" });
                            } else {
                                next();
                            }
                        },
                    },
                ]
            },
            {
                path: "quit_service",
                name: "QuitService",
                component: QuitService,
                meta: { title: "サービス連携解除" },
            },
        ]
    },
    ...commonRoute,
    {
        path: "/:catchAll(.*)",
        redirect: to => {
            return "/";
        }
    },
];

// ルータ生成
const portalRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior,
    routes
});

// イベント処理追加
portalRouter.beforeEach(async(to, from, next) => {
    // 認証状態を取得して保持
    loginStatus.value = await sidClient.isAuthenticated();
    // ログイン必須のページの場合
    if (to.matched.some(record => record.meta.isMemberOnly)) {
        // ログイン状態判定
        if (loginStatus.value) {
            // ログイン済みの場合は対象の画面に遷移する
            next();
        } else {
            // SIDポータルの場合
            next({ name: "Login", query: { retUrl: to.name as string } });
        }
    } else {
        // ログイン必須ではないページはそのまま対象ページに遷移する
        next();
    }
});

/** ポータル画面用ルータ */
export default portalRouter;
