
import { ImageItem, ImageItemKey } from "@/modules/imageInfo";
import { useRouter } from "vue-router";
import { Ref, computed, defineComponent, inject, onMounted, onUnmounted, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { useStore } from "@/store";
import { BaseInfo, ShippingInfo, UserBase } from "@/commons/interfaces";
import { MEMBER_AGREE_INFO_VERSION } from "@/commons/constants";
import { arrayBufferToBase64, readFile } from "@/commons/imageFormatUtility";
import moment from "moment";
import { SID_ERROR_MSG_NOT_AUTHENTICATED, SID_ERROR_MSG_UNAUTHORIZED } from "@sid/sid_client";
import useDeterring from "@/commons/useDeterring";
import { UserBaseItem } from "@/modules/userBaseItem";

export default defineComponent({
    name: "SignupConfirm",
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const userBaseItem = UserBaseItem.inject();
        const userBase = userBaseItem.userInfo as UserBase;
        const agreement = userBaseItem.agreement as Ref<boolean>;
        const { image } = inject(ImageItemKey) as ImageItem;
        const errorMsgRef = ref("");
        const isDispErrorMsgRef = ref(false);
        const nickName = computed(() => userBase.commonAttrInfo?.nickName?.replaceAll("&lt;", "<").replaceAll("&gt;", ">") || "");

        const date = computed(() => {
            const m = moment(userBase.commonAttrInfo.birthday);
            return { year: m.year(), month: m.month() + 1, day: m.date() };
        });

        /**
         * 送付先情報設定
         * @param baseInfo 登録者情報
         * return shippingInfo
         */
        const setShippingInfo = (baseInfo: BaseInfo) => {
            const shippingInfo = new ShippingInfo();
            shippingInfo.lastName = baseInfo.lastName;
            shippingInfo.firstName = baseInfo.firstName;
            shippingInfo.lastKana = baseInfo.lastKana;
            shippingInfo.firstKana = baseInfo.firstKana;
            shippingInfo.zipCode = baseInfo.zipCode;
            shippingInfo.prefecture = baseInfo.prefecture;
            shippingInfo.address = baseInfo.address;
            shippingInfo.building = baseInfo.building;
            shippingInfo.tel = baseInfo.tel;
            return shippingInfo;
        };

        /**
         * プロフィール画像登録
         * @async
         * @param file プロフィール画像
         */
        const imageRegister = async (file: Blob) => {
            if (image.path) {
                const binaryData = await readFile(file);
                const base64Data: any = arrayBufferToBase64(binaryData); 
                await sidClient.requestApi({
                    method: "POST",
                    path: "/user/me/image",
                    body: base64Data
                });
            }
        };

        /**
         * 確認ボタン押下時処理
         * @async
         */
        const register = async() => {
            try {
                // 認証ユーザ名設定
                const authUserName = (await sidClient.getUserData()).Username;
                userBase.userName = authUserName;
            } catch (e) {
                if (e.message === SID_ERROR_MSG_NOT_AUTHENTICATED || e.message === SID_ERROR_MSG_UNAUTHORIZED) {
                    // ログイン画面へ遷移
                    router.push({ name: "Login" });
                    return;
                } else {
                    errorMsgRef.value = "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
                    return;
                }
            }
    
            try {
                // 登録日
                const today = moment(new Date()).format("YYYY-MM-DD");

                // 送付先情報設定
                userBase.shippingInfo[0] = setShippingInfo(userBase.baseInfo);

                // 会員規約合意情報の設定
                userBase.memberAgreeInfo.version = MEMBER_AGREE_INFO_VERSION;
                userBase.memberAgreeInfo.regDate = today;

                // 要求用にオブジェクトをコピー
                const reqUserInfo = Object.assign({}, userBase);

                // ニックネームをエスケープ
                reqUserInfo.commonAttrInfo.nickName = reqUserInfo?.commonAttrInfo?.nickName?.replaceAll("<", "&lt;").replaceAll(">", "&gt;") || "";
                 
                // 基本属性情報登録
                const payload = JSON.parse(JSON.stringify(reqUserInfo));
                await sidClient.createUserAttr(payload);

            } catch (e) {
                if (e.message === "Request failed with status code 500") {
                    // 500：UserAttribute already exists
                    store.dispatch("fetchUserInfo").finally(() => {
                        isDispErrorMsgRef.value = true;
                    });
                    return;                   
                } else {
                    errorMsgRef.value = "基本属性情報の登録に失敗しました。後ほど再度お試し下さい。";
                    return;
                }
            }

            try {
                // プロフィール画像登録
                await imageRegister(image.file);    
            } catch (e) {
                // マイページの基本属性情報更新で登録しなおしてもらえれば良いので、エラーは無視する
            } finally {
                await store.dispatch("fetchUserInfo").finally(() => {
                    // マイページトップ画面へ遷移
                    router.push({ name: "MypageTop" });
                });
            }
        };

        /**
         * ページを離れる場合の確認アラート起動
         * @event
         */
        const confirmSave = (event: any) => {
            event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
        };

        onMounted(() => {
            window.addEventListener("beforeunload", confirmSave);
        });

        onUnmounted(() => {
            window.removeEventListener("beforeunload", confirmSave);
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            userBase,
            agreement,
            image,
            date,
            nickName,
            errorMsgRef,
            isDispErrorMsgRef,
            register
        };
    },
});
