
import { defineComponent, ref } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";

export default defineComponent({
    name: "LinkageChangeEmailRescueProfile",
    props: {
        message: { type: String },
    },
    setup(props) {
        const { linkageInfo } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        const errorMsgRef = ref(props.message);
        const infoMsgRef = ref("");

        // サイト毎の必須情報チェック
        if (!userBaseItem.checkRequired() || !siteUniqItem.checkRequired()) {
            infoMsgRef.value = "ご利用にあたり登録情報の確認をお願いいたします。";
        }

        return {
            linkageInfo,
            errorMsgRef,
            infoMsgRef,
        };
    }
});
