
import { getServiceName } from "@/commons/commonUtility";
import { MANGASAI_LINKAGE_ID } from "@/commons/linkage/sites/mangasai";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { computed, defineComponent } from "vue";

export default defineComponent({
    name: "LinkageTerms",
    props: {
        initialsCalss: { type: String, required: true },
    },
    setup(props) {
        const { linkageInfo, serviceTermUrl } = LinkageItem.inject();

        const serviceName = getServiceName(linkageInfo.linkageId);
        const initialLetterCalss = computed(() => props.initialsCalss.trim());

        return {
            initialLetterCalss,
            serviceName,
            serviceTermUrl,
            linkageInfo,
            MANGASAI_LINKAGE_ID,
        };
    },
});
