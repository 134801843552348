
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, inject, onMounted, ref } from "vue";
import { Form, Field } from "vee-validate";
import { checkConfirmationCode } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";
import { loginStatus } from "@/modules/loginStatus";
import { CognitoItem, CognitoItemKey } from "@/modules/cognitoItem";

export default defineComponent({
    name: "SignupCode",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const { cognitoInfo } = inject(CognitoItemKey) as CognitoItem;     
        const confirmationCodeRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            confirmationCode: checkConfirmationCode
        });

        /**
         * 送信ボタン押下時処理
         * @async
         */
        const confirmConfirmationCode = async () => {
            try {
                // 確認コード検証
                await sidClient.confirmation(cognitoInfo.userName, confirmationCodeRef.value);

                if (cognitoInfo.password === "") {
                    // メールのリンクから飛んできた場合、passwordがセットされていないので、ログイン画面へ遷移
                    router.push({ name: "Login" });
                    return;
                }

                // 念のため、サインアウト
                await sidClient.signOut();
                loginStatus.value = false; 

                // ログイン
                await sidClient.signIn(cognitoInfo.userName, cognitoInfo.password, true);

                // 基本属性情報登録画面へ遷移
                router.push({ name: "SignupProfile" });                
            } catch (e) {
                if (e.code === "ExpiredCodeException") {
                    // メールアドレス確認済の場合
                    errorMsgRef.value = "確認コード有効期限切れです。再度、最初から新規会員登録をお願いいたします。";
                } else if (e.code === "AliasExistsException") {
                    // メールアドレスが既にCognitoに存在する(メールアドレスが既に他ユーザに使用されている)場合
                    errorMsgRef.value = "メールアドレスは既に使用されています。";
                } else {
                    errorMsgRef.value = "コードが確認できませんでした。正しいコードを入力して再実施ください。";
                }
            }
        };

        /**
         * 確認コード再送ボタン押下時処理
         * @async
         */
        const resendConfirmationCode = async () => {
            try {
                // 確認コード再送
                await sidClient.resendConfirmationCode(cognitoInfo.userName);
            } catch (e) {
                errorMsgRef.value = "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
            }
        };

        onMounted(async () => {
            // 検証コードメール内に記載されているリンクから飛んできた場合
            // param:username, query:code
            if (route.params.username && route.query.code) {
                cognitoInfo.userName = route.params.username as string;
                confirmationCodeRef.value = route.query.code as string;
            }
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            confirmationCodeRef,
            errorMsgRef,
            schema,
            confirmConfirmationCode,
            resendConfirmationCode
        };
    },
});
