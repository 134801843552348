
import { defineComponent } from "vue";
import LinkageSignupProgress from "@/components/Linkage/LinkageSignupProgress.vue";

export default defineComponent({
    name: "LinkageSignupConfirm",
    components: {
        LinkageSignupProgress,
    },
    setup() {
        return {
        };
    }
});
