
import { arrayBufferToBase64, readFile } from "@/commons/imageFormatUtility";
import { BaseInfo, ShippingInfo, UserBase } from "@/commons/interfaces";
import { ImageItem, ImageItemKey } from "@/modules/imageInfo";
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import { computed, defineComponent, inject, onMounted, onUnmounted, ref } from "vue";
import { SID_ERROR_MSG_NOT_AUTHENTICATED, SID_ERROR_MSG_UNAUTHORIZED } from "@sid/sid_client";
import { useStore } from "@/store";
import moment from "moment";
import useDeterring from "@/commons/useDeterring";
import { UserBaseItem } from "@/modules/userBaseItem";

export default defineComponent({
    name: "ProfileConfirm",
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const userBaseItem = UserBaseItem.inject();
        const userBase = userBaseItem.userInfo as UserBase;
        const { image } = inject(ImageItemKey) as ImageItem;
        const errorMsgRef = ref("");
        const nickName = computed(() => (typeof(userBase.commonAttrInfo.nickName) === "string") ? userBase.commonAttrInfo.nickName.replaceAll("&lt;","<").replaceAll("&gt;",">") : userBase.commonAttrInfo.nickName);
        const date = computed(() => {
            const m = moment(userBase.commonAttrInfo.birthday);
            return { year: m.year(), month: m.month() + 1, day: m.date() };
        });

        /**
         * 送付先情報設定
         * @param baseInfo 登録者情報
         */
        const setShippingInfo = (baseInfo: BaseInfo) => {
            const shippingInfo = new ShippingInfo();
            shippingInfo.lastName = baseInfo.lastName;
            shippingInfo.firstName = baseInfo.firstName;
            shippingInfo.lastKana = baseInfo.lastKana;
            shippingInfo.firstKana = baseInfo.firstKana;
            shippingInfo.zipCode = baseInfo.zipCode;
            shippingInfo.prefecture = baseInfo.prefecture;
            shippingInfo.address = baseInfo.address;
            shippingInfo.building = baseInfo.building;
            shippingInfo.tel = baseInfo.tel;
            return shippingInfo;
        };

        /**
         * プロフィール画像更新
         * @async
         * @param file プロフィール画像
         */
        const imageUpdate = async (file: Blob) => {
            if (userBase.commonAttrInfo.imagePath) {
                if (!image.path) {
                    // 以前にプロフィール画像を登録していて、削除する場合
                    // プロフィール画像削除
                    await sidClient.requestApi({
                        method: "DELETE",
                        path: "/user/me/image",
                    });
                    return;                    
                } else {
                    if (userBase.commonAttrInfo.imagePath !== image.path) {
                        // 以前にプロフィール画像を登録していて、以前と違う画像を登録する場合
                        // プロフィール画像更新
                        const binaryData = await readFile(file);
                        const base64Data: any = arrayBufferToBase64(binaryData);                                     
                        await sidClient.requestApi({
                            method: "PUT",
                            path: "/user/me/image",
                            body: base64Data
                        });
                        return;
                    }
                    // 以前にプロフィール画像が登録していて、変更しない場合
                    // 特に何もしない
                }
            } else {
                if (image.path) {
                    // 以前にプロフィール画像を登録しておらず、今回登録する場合
                    // プロフィール画像登録
                    const binaryData = await readFile(file);
                    const base64Data: any = arrayBufferToBase64(binaryData);               
                    await sidClient.requestApi({
                        method: "POST",
                        path: "/user/me/image",
                        body: base64Data
                    });
                    return;
                }
                // 以前にプロフィール画像を登録しておらず、今回も登録しない場合
                // 特に何もしない
            }
        };

        /**
         * 確認ボタン押下時処理
         * @async
         */
        const update = async() => {
            try {
                // 認証ユーザ名設定
                const authUserName = (await sidClient.getUserData()).Username;
                userBase.userName = authUserName;
            } catch (e) {
                if (e.message === SID_ERROR_MSG_NOT_AUTHENTICATED || e.message === SID_ERROR_MSG_UNAUTHORIZED) {
                    // ログイン画面へ遷移
                    router.push({ name: "Login" });
                    return;
                } else {
                    errorMsgRef.value = "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
                    return;
                }
            }
    
            try {
                // 送付先情報設定
                userBase.shippingInfo[0] = setShippingInfo(userBase.baseInfo);

                // 要求用にオブジェクトをコピー
                const reqUserInfo = Object.assign({}, userBase);
                // ニックネームをエスケープ
                reqUserInfo.commonAttrInfo.nickName = (typeof(reqUserInfo.commonAttrInfo.nickName) === "string") ? reqUserInfo.commonAttrInfo.nickName.replaceAll("<","&lt;").replaceAll(">","&gt;") : reqUserInfo.commonAttrInfo.nickName;
                // 基本属性情報更新
                const payload = JSON.parse(JSON.stringify(reqUserInfo));
                await sidClient.updateUserAttr(payload);

                try {
                    // プロフィール画像登録
                    await imageUpdate(image.file);
                } catch (error) {
                    console.log(error);
                    errorMsgRef.value = "プロフィール画像の更新に失敗しました。後ほど再度お試し下さい。";
                    return;
                } finally {
                    // 基本属性情報再取得して保持
                    await store.dispatch("fetchUserInfo");
                    userBaseItem.setUserInfo(store.state.userInfo);
                }
                image.path = store.state.userInfo.commonAttrInfo.imagePath;

            } catch (e) {          
                if (e.message === "Request failed with status code 404") {
                    // 基本属性情報再取得して保持
                    await store.dispatch("fetchUserInfo");
                    userBaseItem.setUserInfo(store.state.userInfo);
                    image.path = store.state.userInfo.commonAttrInfo.imagePath;

                    // 別のブラウザで基本属性情報が更新された後に更新を行った場合、バージョン違いによるエラー発生（404：Not Found）
                    // 基本属性情報更新画面へ遷移
                    router.push({ name: "Profile", query: { errorMsg: "基本属性情報の更新に失敗しました。再度お試し下さい。" } });
                    
                    return;
                }
                errorMsgRef.value = "基本属性情報の更新に失敗しました。後ほど再度お試し下さい。";
                return;
            }
            
            // 基本属性情報更新完了へ遷移
            router.push({ name: "ProfileComplete" });            
        };

        /**
         * キャンセルボタン押下時処理
         */
        const cancel = () => {
            // 基本属性情報更新画面へ遷移
            router.push({ name: "Profile" });
        };

        /**
         * ページを離れる場合の確認アラート起動
         * @event
         */
        const confirmSave = (event: any) => {
            event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
        };

        onMounted(() => {
            window.addEventListener("beforeunload", confirmSave);
        });

        onUnmounted(() => {
            window.removeEventListener("beforeunload", confirmSave);
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            userBase,
            image,
            date,
            errorMsgRef,
            nickName,
            update,
            cancel
        };
    },
});
