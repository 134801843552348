
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import useDeterring from "@/commons/useDeterring";
import { getServiceName, isAuthFailed } from "@/commons/commonUtility";
import { useStore } from "@/store";
import { SiteLinkage } from "@/commons/interfaces";

export default defineComponent({
    name: "QuitService",
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const leaveNote = ref("");
        const linkageId = ref(NaN);
        const serviceName = ref("");
        const basePath = ref("");
        const errorMsgRef = ref("");

        /**
         * サービス連携解除ボタン押下時処理
         * @async
         */
        const quitService = async () => {
            try {
                // 連携解除理由（サービス退会理由）は固定
                leaveNote.value = "サービスが終了したため。";

                // 退会理由をエスケープ
                const escapedLeaveNote = leaveNote.value.replaceAll("<", "&lt;").replaceAll(">", "&gt;");
                const reqLeaveNote = {
                    leaveNote1: escapedLeaveNote,
                    leaveNote2: ""
                };

                // サービス連携解除
                const payload = JSON.parse(JSON.stringify(reqLeaveNote));
                await sidClient.requestApi({
                    method: "DELETE",
                    path: `/${basePath.value}/me`,
                    body: payload
                });

                // マイページトップ画面へ遷移
                router.push({ name: "MypageTop" });
            } catch (e) {
                if (isAuthFailed(e)) {
                    // ログイン画面へ遷移
                    router.push({ name: "Login" });
                } else if (e.message === "Request failed with status code 404") {
                    // 解除済の場合、マイページトップ画面へ遷移
                    router.push({ name: "MypageTop" });
                } else {
                    // エラーメッセージ表示
                    errorMsgRef.value = "サービス連携解除に失敗しました。後ほど再度お試し下さい。";
                    return;
                }
            }
        };

        onMounted(() => {
            linkageId.value = Number(route.query.siteLinkageId as string);

            if (Number.isNaN(linkageId.value) || store.state.siteLinkage.find((site: SiteLinkage) => site.link_id === linkageId.value).length === 0) {
                // siteLinkageIdが正しく設定されていない場合、マイページへ遷移
                router.push({ name: "MypageTop" });
                return;
            }

            serviceName.value = getServiceName(linkageId.value);
            basePath.value = store.state.siteLinkage.find((site: SiteLinkage) => site.link_id === linkageId.value)?.base_path;
        });

        return {
            quitService,
            isDeterringRef,
            serviceName,
            errorMsgRef,
            asyncDeterringWrap,
        };
    },
});
