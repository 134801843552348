
import { defineComponent, inject, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { ImageItem, ImageItemKey } from "@/modules/imageInfo";
import { UserBaseItem } from "@/modules/userBaseItem";

export default defineComponent({
    name:"ProfileParent",
    setup() {
        const store = useStore();
        const userBaseItem = UserBaseItem.inject();
        const imageItem = inject(ImageItemKey) as ImageItem;

        const isMounted = ref(false);

        onMounted(async() => {
            try {
                // 基本属性情報再取得して保持
                await store.dispatch("fetchUserInfo");
                userBaseItem.setUserInfo(store.state.userInfo);
                imageItem.image.path = store.state.userInfo.commonAttrInfo.imagePath;
            } catch (e) {
                // 特に何もしない
            }
            // 子コンポーネントのonMountedが先に動くので、抑止
            isMounted.value = true;
        });

        return {
            isMounted
        };
    },
});
