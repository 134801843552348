
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, reactive, ref } from "vue";
import { Field, Form } from "vee-validate";
import { checkNewPassword, checkNewPasswordRetype, checkOldPassword } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isAuthFailed } from "@/commons/commonUtility";

export default defineComponent({
    name: "LinkageChangePassword",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, fetchToken, routeNames } = LinkageItem.inject();

        const oldPasswordRef = ref("");
        const newPasswordRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            oldPassword: checkOldPassword,
            newPassword: checkNewPassword,
            newPasswordRetype: checkNewPasswordRetype
        });

        const oldPasswordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const newPasswordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const newPasswordRetypeToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean }
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * 変更ボタン押下時処理
         * @async
         */
        const changePassword = async () => {
            // パスワード更新
            await sidClient.changePassword(oldPasswordRef.value, newPasswordRef.value)
                .then(() => {
                    // パスワード変更完了画面へ遷移
                    router.push({ name: routeNames.CHANGE_PASSWORD_COMPLETE });
                })
                .catch(reason => {
                    if (isAuthFailed(reason)) {
                        // 本人確認画面へ遷移
                        router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: route.name as string }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                    } else if (reason.code === "NotAuthorizedException") {
                        // 現在のパスワード誤り
                        errorMsgRef.value = "現在のパスワードが間違っています。入力内容をご確認のうえ再度お試しください。";
                    } else {
                        errorMsgRef.value = "パスワード変更に失敗しました。後ほど再度お試し下さい。";
                    }
                });
        };

        const backLinkageSite = async () => {
            if (linkageInfo.loginStateUnused) {
                await router.back();
            } else {
                // コールバック先で振り分けが行われるため、トークン発行
                await asyncDeterringWrap(fetchToken, this, sidClient).catch(error => {
                    // エラー画面遷移
                    router.push({ name: routeNames.GENERAL_ERROR, params:{ message: "認証処理に失敗しました。" } });
                });
                // 各サービスサイトのマイページTOPへ戻る（コールバック）
                window.location.href = linkageInfo.callbackUrlWithToken as string;
            }
        };

        return {
            isDeterringRef,
            asyncDeterringWrap,
            oldPasswordRef,
            newPasswordRef,
            errorMsgRef,
            schema,
            PASSWORD_MAX_LENGTH,
            oldPasswordToggle,
            newPasswordToggle,
            newPasswordRetypeToggle,
            switchPasswordFieldType,
            changePassword,
            backLinkageSite
        };
    },
});
