
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { checkNewEmail, checkNewEmailRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isAuthFailed, reEncodeUriComponent } from "@/commons/commonUtility";

export default defineComponent({
    name: "LinkageChangeEmail",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const route = useRoute();
        const router = useRouter();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, fetchToken, routeNames, emailDomains } = LinkageItem.inject();

        const currentEmailRef = ref("");
        const newEmailRef = ref("");
        const newEmailRetypeRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            newEmail: checkNewEmail,
            newEmailRetype: checkNewEmailRetype
        });

        /**
         * 変更ボタン押下時処理
         * @async
         */
        const changeEmail = async () => {
            // メールアドレス変更
            try {
                await sidClient.changeEmail(
                    newEmailRef.value,
                    {
                        LinkageName: linkageInfo?.pathName,
                        CodeChallenge: linkageInfo?.codeChallenge,
                        CallbackUrl: reEncodeUriComponent(linkageInfo?.callbackUrl)
                    }
                );
                // メールアドレス変更確認画面へ遷移
                await router.push({ name: routeNames.CHANGE_EMAIL_CONFIRM, query: { newEmail: newEmailRef.value } });
            } catch(reason) {
                if (isAuthFailed(reason)) {
                    // 本人確認画面へ遷移
                    await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: route.name as string }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                } else if (reason.response?.data === "the email has already been changed") {
                    errorMsgRef.value = "現在のメールアドレスとは異なるメールアドレスをご入力ください。";
                } else if (reason.response?.data === "the email address is not allowed") {
                    errorMsgRef.value = "このメールアドレスへの変更はできません。";
                } else {
                    errorMsgRef.value = "メールアドレス変更に失敗しました。後ほど再度お試し下さい。";
                }
            }
        };

        const backLinkageSite = async () => {
            if (linkageInfo.loginStateUnused) {
                await router.back();
            } else {
                // コールバック先で振り分けが行われるため、トークン発行
                await asyncDeterringWrap(fetchToken, this, sidClient).catch(error => {
                    // エラー画面遷移
                    router.push({ name: routeNames.GENERAL_ERROR, params:{ message: "認証処理に失敗しました。" } });
                });
                // 各サービスサイトのマイページTOPへ戻る（コールバック）
                window.location.href = linkageInfo.callbackUrlWithToken as string;
            }
        };

        onMounted(async () => {
            try {
                const userData = await sidClient.getUserData();
                const email = userData.UserAttributes.filter((obj) => obj.Name === "email")[0].Value;
                currentEmailRef.value = email;
            } catch (e) {
                await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: router.currentRoute.value.name as string } });
                return;
            }
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            currentEmailRef,
            newEmailRef,
            newEmailRetypeRef,
            errorMsgRef,
            schema,
            changeEmail,
            backLinkageSite,
            emailDomains
        };
    },
});
