
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { checkEmail } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { reEncodeUriComponent } from "@/commons/commonUtility";

export default defineComponent({
    name: "LinkageResetPassword",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, routeNames, emailDomains } = LinkageItem.inject();

        const emailRef = ref("");
        const errorMsgRef = ref("");
        const returnUrlRef = ref("");

        const schema = Yup.object().shape({
            email: checkEmail
        });

        /**
         * パスワードリセット要求
         * @async
         */
        const forgotPassword = async () => {
            await sidClient.forgotPassword(
                emailRef.value,
                {
                    LinkageName: linkageInfo?.pathName,
                    CodeChallenge: linkageInfo?.codeChallenge,
                    CallbackUrl: reEncodeUriComponent(linkageInfo?.callbackUrl)
                }
            )
                .then(() => {
                    if (route.name === routeNames.SIGNUP_RESET_PASSWORD) {
                        // eslint-disable-next-line
                        router.push({name: routeNames.SIGNUP_SET_PASSWORD, query: {reset_email: emailRef.value, retUrl: returnUrlRef.value}});
                    } else {
                        // eslint-disable-next-line
                        router.push({name: routeNames.SET_PASSWORD, query: {reset_email: emailRef.value, retUrl: returnUrlRef.value}});    
                    }
                })
                .catch(() => {
                    errorMsgRef.value = "エラーが発生しました。お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
                });
        };

        onMounted((): void => {
            if (route.query.reset_email)
                emailRef.value = route.query.reset_email as string;
            if (route.query.retUrl)
                returnUrlRef.value = route.query.retUrl as string;
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            emailRef,
            errorMsgRef,
            schema,
            forgotPassword,
            routeNames,
            emailDomains
        };
    },
});
