<template>
    <h1 class="p-about__ttl--primary c-ttl--primary">お問い合わせ 受付完了</h1>
    <section class="p-about__sec">
        <p class="p-about__txt">この度は、お問い合わせいただきありがとうございます。<br/>内容を確認し、順次回答させていただきます。</p>
        <p class="p-about__txt">原則3営業日以内に回答いたしますが、確認に時間を要する内容や土日祝日を挟む場合は、回答にお時間を頂く場合がございます。予めご了承ください。</p>
        <p class="p-about__txt"> ※年末年始やゴールデンウィークに関しては、通常より回答までお時間をいただきます。<br/>年末年始やゴールデンウィークの営業については<a href="https://storehelp.hpplus.jp/faq/show/3018?category_id=1&site_domain=default" target="_blank"  class="c-link c-link--primary">こちら</a>をご確認ください。</p>
        <div class="p-about__cap">
            <p class="p-about__cap">ドメイン指定受信を設定している場合は「@cs.id.shueisha.co.jp」から受信ができるように設定してください。</p>
            <p class="p-about__cap">フリーメールをご利用の場合、コンタクトセンターからご案内メールが自動的に「迷惑メールフォルダ」に振り分けられてしまう場合がございますので、ご注意ください。</p>
        </div>
        <div class="p-about__btn">
            <router-link :to="{ name: 'Top' }" class="c-btn c-btn--outline"><span>トップページに戻る</span></router-link>
        </div>
    </section>
</template>
