
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { defineComponent, inject, onMounted, onUnmounted, ref } from "vue";
import { useSidClient } from "@/plugins/auth";
import { SID_ERROR_MSG_NOT_AUTHENTICATED } from "@sid/sid_client";
import useDeterring from "@/commons/useDeterring";
import { loginStatus } from "@/modules/loginStatus";
import { UserBaseItem } from "@/modules/userBaseItem";
import { LeaveNoteItem, LeaveNoteItemKey } from "@/modules/leaveNote";
import { SiteLinkage } from "@/commons/interfaces";

export default defineComponent({
    name: "Withdrawal",
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const userBaseItem = UserBaseItem.inject();
        const { leaveNote } = inject(LeaveNoteItemKey) as LeaveNoteItem;
        const errorMsgRef = ref("");

        /**
         * OKボタン押下時処理
         * @async
         */
        const withdrawal = async () => {
            try {
                // 要求用にオブジェクトをコピー
                const reqLeaveNote = Object.assign({}, leaveNote);
                // 退会理由をエスケープ
                reqLeaveNote.leaveNote1 = (typeof(reqLeaveNote.leaveNote1) === "string") ? reqLeaveNote.leaveNote1.replaceAll("<","&lt;").replaceAll(">","&gt;") : reqLeaveNote.leaveNote1;
                reqLeaveNote.leaveNote2 = (typeof(reqLeaveNote.leaveNote2) === "string") ? reqLeaveNote.leaveNote2.replaceAll("<","&lt;").replaceAll(">","&gt;") : reqLeaveNote.leaveNote2;
                // 基本属性情報削除
                const payload = JSON.parse(JSON.stringify(reqLeaveNote));
                await sidClient.deleteUserAttr(payload);
            } catch (e) {                
                if (e.response?.data === "This user is still registered services.") {
                    // サービス登録中にAPIコールされた場合
                    // 基本属性情報再取得して保持
                    await store.dispatch("fetchUserInfo");
                    userBaseItem.setUserInfo(store.state.userInfo);
                    // 退会画面へ遷移
                    router.push({ name: "Withdrawal" });
                    return;
                } else if (e.message !== "Request failed with status code 404") {
                    // 既に基本属性情報がない場合（404：Not Found）→アカウント削除へ
                    // それ以外→エラーメッセージ表示
                    errorMsgRef.value = "基本属性情報の削除に失敗しました。後ほど再度お試し下さい。";
                    return;
                }
            }

            // 各サービス既存会員が移行後に集英社IDを使ってサービスログインを行わなかった場合、基本属性情報上はサービス連携されていない
            // その状態で集英社IDを退会すると、サイト固有情報が残ってしまうため、念のため各サービスのサイト固有情報削除をする

            // 退会理由は空文字
            const reqLeaveNote = {
                leaveNote1: "",
                leaveNote2: ""
            };

            // サービス連携解除
            const leavePromises = store.state.siteLinkage.map((site: SiteLinkage) => (
                sidClient.requestApi({
                    method: "DELETE",
                    path: `/${site.base_path}/me`,
                    body: JSON.parse(JSON.stringify(reqLeaveNote)),
                })
            ));
            await Promise.allSettled(leavePromises);

            try {
                // アカウント削除
                await sidClient.deleteUserByOwn();
            } catch (e) {
                if (e.message === SID_ERROR_MSG_NOT_AUTHENTICATED) {
                    errorMsgRef.value = "アカウント削除に失敗しました。再度ログイン後、お試し下さい。";
                    return;
                } else if (e.code !== "UserNotFoundException") {
                    errorMsgRef.value = "アカウント削除に失敗しました。後ほど再度お試し下さい。";
                    return;
                }
            }

            // サインアウト（グローバル）
            await sidClient.globalSignOut();
            loginStatus.value = false;
            // 退会完了画面へ遷移
            router.push({ name: "WithdrawalComplete" });
        };

        /**
         * キャンセルボタン押下時処理
         */
        const cancel = () => {
            // 退会画面へ遷移
            router.push({ name: "Withdrawal" });
        };

        /**
         * ページを離れる場合の確認アラート起動
         * @event
         */
        const confirmSave = (event: any) => {
            event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
        };

        onMounted(() => {
            window.addEventListener("beforeunload", confirmSave);
        });

        onUnmounted(() => {
            window.removeEventListener("beforeunload", confirmSave);
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            leaveNote,
            errorMsgRef,
            withdrawal,
            cancel
        };
    },
});
