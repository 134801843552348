
import { useStore } from "@/store";
import { computed, defineComponent, onMounted } from "vue";
import { UserBaseItem } from "@/modules/userBaseItem";
import { RouterLink } from "vue-router";
import { getSiteRegistration } from "@/commons/commonUtility";

export default defineComponent({
    name: "MypageTop",
    setup() {
        const store = useStore();
        const { registeredSiteList, notRegisteredSiteList } = getSiteRegistration(store.state.siteLinkage, store.state.userInfo);
        const userBaseItem = UserBaseItem.inject();
        const nickName = computed(() => (typeof (store.state.userInfo.commonAttrInfo.nickName) === "string") ? store.state.userInfo.commonAttrInfo.nickName.replaceAll("&lt;", "<").replaceAll("&gt;", ">") : store.state.userInfo.commonAttrInfo.nickName);
        const logoImageBaseURL = process.env.VUE_APP_BASE_URL + "/img/mypage/";
        onMounted(async () => {
            try {
                // 基本属性情報再取得して保持
                await store.dispatch("fetchUserInfo");
                userBaseItem.setUserInfo(store.state.userInfo);
            }
            catch (e) {
                // 特に何もしない
            }
        });
        return {
            store,
            nickName,
            logoImageBaseURL,
            registeredSiteList,
            notRegisteredSiteList
        };
    },
    components: { RouterLink }
});
