
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { checkConfirmationCode } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isAuthFailed, reEncodeUriComponent } from "@/commons/commonUtility";

export default defineComponent({
    name: "LinkageChangeEmailConfirm",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, routeNames } = LinkageItem.inject();

        const newEmailRef = ref("");
        const confirmationCodeRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            confirmationCode: checkConfirmationCode
        });

        /**
         * 送信ボタン押下時処理
         * @async
         */
        const confirmConfirmationCode = async () => {
            // Emailアドレス変更による検証
            try {
                await sidClient.verifyEmail(confirmationCodeRef.value);
                // メールアドレス変更完了画面へ遷移
                await router.push({ name: routeNames.CHANGE_EMAIL_COMPLETE });
            } catch (reason) {
                if (isAuthFailed(reason)) {
                    // 本人確認画面へ遷移
                    await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: routeNames.CHANGE_EMAIL as string }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                } else if (reason.response?.data === "invalid verification code") {
                    errorMsgRef.value = "コードが確認できませんでした。正しいコードを入力して再実施ください。";
                } else {
                    errorMsgRef.value = "メールアドレス変更に失敗しました。後ほど再度お試し下さい。";
                }
            }
        };

        /**
         * 確認コード再送ボタン押下時処理
         * @async
         */
        const resendAttrConfirmationCode = async () => {
            // メールアドレス変更再実施
            try {
                await sidClient.changeEmail(
                    newEmailRef.value,
                    {
                        LinkageName: linkageInfo?.pathName,
                        CodeChallenge: linkageInfo?.codeChallenge,
                        CallbackUrl: reEncodeUriComponent(linkageInfo?.callbackUrl)
                    }
                );
            } catch (reason) {
                if (isAuthFailed(reason)) {
                    // 本人確認画面へ遷移
                    await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: routeNames.CHANGE_EMAIL as string }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                } else if (reason.response?.data === "the email has already been changed") {
                    errorMsgRef.value = "既にメールアドレス変更は完了しています。";
                } else if (reason.response?.data === "the email address is not allowed") {
                    errorMsgRef.value = "このメールアドレスへの変更はできません。";
                } else {
                    errorMsgRef.value = "メールアドレス変更に失敗しました。後ほど再度お試し下さい。";
                }
            }
        };

        /**
         * キャンセルボタン押下時処理
         * @async
         */
        const cancelPassword = async () => {
            await router.push({ name: routeNames.CHANGE_EMAIL });
        };

        onMounted(() => {
            newEmailRef.value = route.query.newEmail as string;
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            newEmailRef,
            confirmationCodeRef,
            errorMsgRef,
            schema,
            confirmConfirmationCode,
            resendAttrConfirmationCode,
            cancelPassword
        };
    },
});
