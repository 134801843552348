
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, ref } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import useDeterring from "@/commons/useDeterring";
import { getServiceName, isAuthFailed } from "@/commons/commonUtility";
import { MANGASAI_LINKAGE_ID } from "@/commons/linkage/sites/mangasai";
import { loginStatus } from "@/modules/loginStatus";

export default defineComponent({
    name: "LinkageWithdrawal",
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { linkageInfo, fetchToken, routeNames, siteUniqApiPath, siteDefaultCallbackUrl } = LinkageItem.inject();
        const { isDeterringRef, setNotRelease, asyncDeterringWrap } = useDeterring();

        const leaveNote = ref("");

        const serviceName = getServiceName(linkageInfo.linkageId);
        
        let withdrawalBtnWord = "";
        if (serviceName) {
            withdrawalBtnWord = linkageInfo.linkageId === MANGASAI_LINKAGE_ID ? serviceName + "への参加を終了（退会）する" : serviceName + "を退会";
        } else {
            withdrawalBtnWord = "退会する";
        }

        asyncDeterringWrap(fetchToken, this, sidClient).catch(error => {
            // エラー画面遷移
            router.push({ name: routeNames.GENERAL_ERROR, params:{ message: "認証処理に失敗しました。" } });
        });

        /**
         * 退会ボタン押下時処理
         * @async
         */
        const withdrawal = async () => {
            try {
                // 退会理由をエスケープ
                const escapedLeaveNote = leaveNote.value.replaceAll("<", "&lt;").replaceAll(">", "&gt;");
                const reqLeaveNote = {
                    leaveNote1: escapedLeaveNote,
                    leaveNote2: ""
                };
                // サービス連携解除
                const payload = JSON.parse(JSON.stringify(reqLeaveNote));
                await sidClient.requestApi({
                    method: "DELETE",
                    path: siteUniqApiPath,
                    body: payload
                });

                // サインアウト
                await sidClient.signOut();
                loginStatus.value = false;

                // コールバック
                if (linkageInfo.loginStateUnused) {
                    window.location.href = siteDefaultCallbackUrl as string;
                } else {
                    setNotRelease();
                    window.location.href = linkageInfo.callbackUrlWithToken as string;
                }
            } catch (e) {
                if (isAuthFailed(e)) {
                    // 本人確認画面へ遷移
                    await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: route.name as string }, params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" } });
                } else if (e.message === "Request failed with status code 404") {
                    // 退会済の場合、コールバック
                    if (linkageInfo.loginStateUnused) {
                        window.location.href = siteDefaultCallbackUrl as string;
                    } else {
                        setNotRelease();
                        window.location.href = linkageInfo.callbackUrlWithToken as string;
                    }
                } else {
                    // エラー画面遷移
                    await router.push({ name: routeNames.GENERAL_ERROR, params: { message: "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。" } });
                }
            }
        };

        return {
            leaveNote,
            withdrawalBtnWord,
            withdrawal,
            isDeterringRef,
            asyncDeterringWrap,
        };
    },
});
