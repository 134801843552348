import { SidUserClient } from "@sid/sid_client";
import { BaseInfo, ChoiceInfo, CommonAttrInfo, LinkageUserData, ManageInfo, MemberAgreementInfo, ShippingInfo, SiteInfo, UserBase, UserUomo } from "@/commons/interfaces";
import { InjectionKey, reactive } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import UomoProfile from "@/components/Linkage/Sites/UomoProfile.vue";
import UomoConfirm from "@/components/Linkage/Sites/UomoConfirm.vue";
import UomoLoginCustom from "@/components/Linkage/Sites/UomoLoginCustom.vue";

// UOMO関連定数定義
/** 連携ID */
export const UOMO_LINKAGE_ID = 7;
/** 連携名称 */
export const UOMO_LINKAGE_NAME = "Uomo";
/** 連携パス */
export const UOMO_PATH = UOMO_LINKAGE_NAME.toLowerCase();
/** サイト固有情報APIパス */
export const UOMO_SITE_UNIQ_API_PATH = "/uomo/me";
/** デフォルトコールバックURL */
export const UOMO_DEFAULT_CALLBACK_URL = process.env.VUE_APP_UOMO_DEFAULT_CALLBACK_URL;
/** 許容コールバックURL */
export const UOMO_ACCEPT_CALLBACK_URLS = process.env.VUE_APP_UOMO_ACCEPT_CALLBACK_URLS.split(",").map((url: string) => `^${url}(\\?.*)?$`);
/** サービス規約URL */
export const UOMO_SERVICE_TERM_URL = process.env.VUE_APP_UOMO_SERVICE_TERM_URL;
/** メールアドレスドメイン */
export const UOMO_EMAIL_DOMAINS = [
    "webuomo.jp",
    "webuomo.sid.shueisha.co.jp",
];
/** 基本属性情報入力 */
export const UOMO_PROFILE_COMPONENT = UomoProfile;
/** 基本属性情報確認 */
export const UOMO_CONFIRM_COMPONENT = UomoConfirm;
/** ログイン画面カスタムパーツ */
export const UOMO_LOGIN_CUSTOM_COMPONENT = UomoLoginCustom;

/**
 * UOMO連携画面用基本属性情報管理クラス
 */
export class UomoUserBase implements LinkageUserData<UserBase> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> = Symbol("UomoUserBase");

    /** 基本属性情報 */
    public readonly data: UserBase = reactive(new UserBase());
    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserBase());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserBase {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserBase): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.baseInfo = Object.assign(new BaseInfo(), obj.baseInfo);
        this.data.shippingInfo = obj.shippingInfo?.map(inf => Object.assign(new ShippingInfo(), inf)) || [];
        this.data.siteInfo = obj.siteInfo?.map(inf => Object.assign(new SiteInfo(), inf)) || [];
        this.data.commonAttrInfo = Object.assign(new CommonAttrInfo(), obj.commonAttrInfo);
        this.data.memberAgreeInfo = Object.assign(new MemberAgreementInfo(), obj.memberAgreeInfo);
        this.data.manageInfo = Object.assign(new ManageInfo(), obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.getUserAttr()
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserBase();   
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // 基本属性情報の有無
        if (!this.data.identityId) return false;
        // 登録者情報
        // if (!this.data.baseInfo.firstName) return false;
        // if (!this.data.baseInfo.lastName) return false;
        // if (!this.data.baseInfo.firstKana) return false;
        // if (!this.data.baseInfo.lastKana) return false;
        // if (!this.data.baseInfo.zipCode) return false;
        // if (!this.data.baseInfo.prefecture) return false;
        // if (!this.data.baseInfo.address) return false;
        // if (!this.data.baseInfo.building) return false;
        if (!this.data.baseInfo.email) return false;
        // if (!this.data.baseInfo.tel) return false;
        // 属性情報
        // if (!this.data.commonAttrInfo.gender) return false;
        // if (!this.data.commonAttrInfo.birthday) return false;
        // if (!this.data.commonAttrInfo.imagePath) return false;
        // if (!this.data.commonAttrInfo.nickName) return false;
        // if (this.data.commonAttrInfo.isMarried === null || this.data.commonAttrInfo.isMarried === undefined) return false;
        // if (this.data.commonAttrInfo.hasChild === null || this.data.commonAttrInfo.hasChild === undefined) return false;
        // if (!this.data.commonAttrInfo.bloodType) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> {
        return UomoUserBase.injectionKey;
    }

}

/**
 * UOMO連携画面用サイト固有情報管理クラス
 */
export class UomoSiteUniq implements LinkageUserData<UserUomo> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserUomo>>> = Symbol("UserUomo");

    /** UOMOサイト固有情報 */
    public readonly data: UserUomo = reactive(new UserUomo());

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserUomo());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserUomo {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserUomo): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.job = obj.job;
        this.data.businessType = obj.businessType;
        this.data.income = obj.income;
        this.data.drivingLicence = obj.drivingLicence;
        this.data.isSmoking = obj.isSmoking;
        this.data.drinking = obj.drinking;
        this.data.isSendMailMagazine = obj.isSendMailMagazine;
        this.data.manageInfo = Object.assign({}, obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.requestApi({ method: "GET", path: UOMO_SITE_UNIQ_API_PATH })
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserUomo();   
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の有無
        if (this.data.job === null || this.data.job === undefined) return false;
        if (this.data.businessType === null || this.data.businessType === undefined) return false;
        // if (this.data.income === null || this.data.income === undefined) return false;
        // if (this.data.drivingLicence === null || this.data.drivingLicence === undefined) return false;
        // if (this.data.isSmoking === null || this.data.isSmoking === undefined) return false;
        // if (this.data.drinking === null || this.data.drinking === undefined) return false;
        // if (this.data.isSendMailMagazine === null || this.data.isSendMailMagazine === undefined) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserUomo>>> {
        return UomoSiteUniq.injectionKey;
    }
}

export const JOB_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "会社員" },
    { value: 1, text: "経営者・役員" },
    { value: 2, text: "契約社員" },
    { value: 3, text: "派遣スタッフ" },
    { value: 4, text: "自営業" },
    { value: 5, text: "フリーランス" },
    { value: 6, text: "公務員" },
    { value: 7, text: "パート・アルバイト" },
    { value: 8, text: "専業主夫・主婦" },
    { value: 9, text: "無職" },
    { value: 10, text: "学生・生徒" },
    { value: 99, text: "その他" }
];

export const BUSINESS_TYPE_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "メディア・広告" },
    { value: 1, text: "ファッション" },
    { value: 2, text: "ビューティ" },
    { value: 3, text: "メーカー" },
    { value: 4, text: "IT・情報" },
    { value: 5, text: "金融・証券・保険" },
    { value: 6, text: "小売" },
    { value: 7, text: "建設" },
    { value: 8, text: "商社・卸" },
    { value: 9, text: "旅行・レジャー" },
    { value: 10, text: "飲食" },
    { value: 11, text: "運輸・流通" },
    { value: 12, text: "エネルギー" },
    { value: 13, text: "住宅・不動産" },
    { value: 14, text: "医療・福祉" },
    { value: 15, text: "弁護士・会計士・司法" },
    { value: 16, text: "教育・学術・研究機関" },
    { value: 17, text: "官公庁" },
    { value: 99, text: "その他" }
];

export const INCOME_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "400万円未満" },
    { value: 1, text: "400～600万円" },
    { value: 2, text: "600～800万円" },
    { value: 3, text: "800～1000万円" },
    { value: 4, text: "1000～1500万円" },
    { value: 5, text: "1500～2000万円" },
    { value: 6, text: "2000万円以上" }
];

export const DRIVING_LICENCE_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "有り" },
    { value: 1, text: "無し" },
    { value: 2, text: "できれば取得したい" }
];

export const IS_SMOKING_LIST: ChoiceInfo<boolean>[] = [
    { value: null, text: "選択してください" },
    { value: true, text: "喫煙する" },
    { value: false, text: "喫煙しない" }
];

export const DRINKING_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "週２～３回" },
    { value: 1, text: "月１回程度" },
    { value: 2, text: "毎日" },
    { value: 3, text: "飲まない" }
];
