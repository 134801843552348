
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Field, Form } from "vee-validate";
import { checkConfirmationCode, checkNewPassword, checkNewPasswordRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { reEncodeUriComponent } from "@/commons/commonUtility";

export default defineComponent({
    name: "LinkageSetPassword",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, routeNames } = LinkageItem.inject();

        const emailRef = ref("");
        const confirmationCodeRef = ref("");
        const newPasswordRef = ref("");
        const newPasswordRetypeRef = ref("");
        const errorMsgRef = ref("");
        const returnUrlRef = ref("");

        const schema = Yup.object().shape({
            confirmationCode: checkConfirmationCode,
            newPassword: checkNewPassword,
            newPasswordRetype: checkNewPasswordRetype
        });

        const newPasswordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const newPasswordRetypeToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean }
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * 送信ボタン押下時処理
         * @async
         */
        const confirmPassword = async () => {
            // パスワードリセット確認
            await sidClient.confirmForgotPassword(emailRef.value, newPasswordRef.value, confirmationCodeRef.value)
                .then(async () => {
                    if (linkageInfo.email) {
                        if (route.name === routeNames.SIGNUP_SET_PASSWORD) {
                            await router.push({ name: routeNames.SIGNUP_IDENTIFICATION, query: { retUrl: returnUrlRef.value }, params:{ message: "パスワードの再設定が完了しました。再度ログインをお願いします。" } });
                        } else {
                            await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: returnUrlRef.value }, params:{ message: "パスワードの再設定が完了しました。再度ログインをお願いします。" } });
                        }
                    } else {
                        await router.push({ name: routeNames.LOGIN, params:{ message: "パスワードの再設定が完了しました。再度ログインをお願いします。" } });
                    }
                })
                .catch((reason) => {
                    if (reason.code === "CodeMismatchException") {
                        // 確認コードが間違っていた場合
                        errorMsgRef.value = "コードが確認できませんでした。正しいコードを入力して再実施ください。";
                    } else {
                        errorMsgRef.value = "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
                    }
                });
        };

        /**
         * 確認コード再送ボタン押下時処理
         * @async
         */
        const forgotPassword = async () => {
            // パスワードリセット要求
            await sidClient.forgotPassword(
                emailRef.value,
                {
                    LinkageName: linkageInfo?.pathName,
                    CodeChallenge: linkageInfo?.codeChallenge,
                    CallbackUrl: reEncodeUriComponent(linkageInfo?.callbackUrl)
                }
            )
                .catch(() => {
                    errorMsgRef.value = "エラーが発生しました。お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
                });
        };

        /**
         * キャンセルボタン押下時処理
         * @async
         */
        const cancelPassword = async () => {
            if (linkageInfo.email) {
                if (route.name === routeNames.SIGNUP_SET_PASSWORD) {
                    await router.push({ name: routeNames.SIGNUP_IDENTIFICATION });
                } else {
                    await router.push({ name: routeNames.IDENTIFICATION, query: { retUrl: returnUrlRef.value } });
                }
            } else {
                await router.push({ name: routeNames.LOGIN });
            }
        };

        onMounted((): void => {
            emailRef.value = route.query.reset_email as string;
            returnUrlRef.value = route.query.retUrl as string;
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            confirmationCodeRef,
            newPasswordRef,
            newPasswordRetypeRef,
            errorMsgRef,
            schema,
            PASSWORD_MAX_LENGTH,
            newPasswordToggle,
            newPasswordRetypeToggle,
            switchPasswordFieldType,
            confirmPassword,
            forgotPassword,
            cancelPassword,
            routeNames
        };
    },
});
