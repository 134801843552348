
import { computed, defineComponent, onMounted, provide, ref } from "vue";
import { useStore } from "@/store";
import SideMenu from "@/components/Portal/SideMenu.vue";
import { useRoute, useRouter } from "vue-router";
import { ImageItemKey, createImageItem } from "@/modules/imageInfo";
import { UserBaseItem } from "@/modules/userBaseItem";

export default defineComponent({
    name: "Mypage",
    components: {
        SideMenu
    },
    setup() {

        const userBaseItem = UserBaseItem.create();
        userBaseItem.provide();
        const imageItem = createImageItem();
        provide(ImageItemKey, imageItem);

        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        const waitViewRef = ref(false);
        const isDispSideMenu = computed(() => route.name !== "QuitService");

        onMounted(async () => {
            if (!store.state.userInfo.identityId) {
                // 基本属性情報登録画面へ遷移
                router.push({ name: "SignupProfile" });
                return;
            }
            userBaseItem.setUserInfo(store.state.userInfo);
            imageItem.image.path = store.state.userInfo.commonAttrInfo.imagePath;

            waitViewRef.value = true;
        });

        return {
            waitViewRef,
            isDispSideMenu
        };
    },
});
