import { UserBase } from "@/commons/interfaces";
import { inject, InjectionKey, provide, reactive, ref } from "vue";
import { SidUserClient } from "@sid/sid_client";

const UserBaseItemKey: InjectionKey<UserBaseItem> = Symbol("UserBaseItem");

export class UserBaseItem {

    public static create(...dummy: any[]) {
        return new UserBaseItem();
    }

    public static inject(): UserBaseItem {
        const info = inject(UserBaseItemKey);
        if (info) {
            return info;
        }
        throw new Error("not provided.");
    }

    public readonly userInfo = reactive(new UserBase())

    public readonly agreement = ref(false);

    public setUserInfo(userBaseVal: UserBase) {
        this.userInfo.identityId = userBaseVal.identityId;
        this.userInfo.userName = userBaseVal.userName;
        this.userInfo.baseInfo = Object.assign({
            lastName: "",
            firstName: "",
            lastKana: "",
            firstKana: "",
            zipCode: "",
            prefecture: "",
            address: "",
            building: "",
            email: "",
            tel: ""
        }, userBaseVal.baseInfo);
        this.userInfo.shippingInfo = userBaseVal.shippingInfo?.map(inf => Object.assign({}, inf)) || [];
        this.userInfo.siteInfo = userBaseVal.siteInfo?.map(inf => Object.assign({}, inf)) || [];
        this.userInfo.commonAttrInfo = Object.assign({
            gender: null,
            birthday: "",
            imagePath: "",
            nickName: "",
            isMarried: null,
            hasChild: null,
            bloodType: null
        }, userBaseVal.commonAttrInfo);
        this.userInfo.memberAgreeInfo = Object.assign({}, userBaseVal.memberAgreeInfo);
        this.userInfo.manageInfo = Object.assign({}, userBaseVal.manageInfo);
    }

    public async fetch(sidClient: SidUserClient) {
        const response = await sidClient.getUserAttr().then(res => res.data).catch(e => new UserBase());
        this.setUserInfo(response);
        return response;
    }

    public provide() {
        provide(UserBaseItemKey, this);
    }
}