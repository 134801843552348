
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { defineComponent, reactive, ref } from "vue";
import { Form, Field } from "vee-validate";
import { checkNewPassword, checkNewPasswordRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";

export default defineComponent({
    name: "NewPassword",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const newPasswordRef = ref("");
        const newPasswordRetypeRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            newPassword: checkNewPassword,
            newPasswordRetype: checkNewPasswordRetype 
        });

        const newPasswordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const newPasswordRetypeToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean } 
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };

        /**
         * パスワード設定
         * @async
         */
        const setPassword = async () => {
            try {
                // パスワード設定要求
                await sidClient.continueSignInNewPassword(newPasswordRef.value);
                // 基本属性情報取得
                await store.dispatch("fetchUserInfo");
                // マイページトップ画面へ遷移
                router.push({ name: "MypageTop" });
            } catch (e) {
                console.log(e);
                errorMsgRef.value = "パスワード設定に失敗しました。後ほど再度お試し下さい。";
                await sidClient.isAuthenticated().then(isAuth => {
                    if (isAuth) {
                        // 基本属性情報取得で失敗した場合、ログイン情報状態になるため、サインアウトしておく
                        sidClient.signOut();
                    }
                });
            }
        };
        
        return {
            isDeterringRef,
            asyncDeterringWrap,
            newPasswordRef,
            newPasswordRetypeRef,
            errorMsgRef,
            schema,
            PASSWORD_MAX_LENGTH,
            newPasswordToggle,
            newPasswordRetypeToggle,
            switchPasswordFieldType,
            setPassword
        };
    },
});
