import { SidUserClient } from "@sid/sid_client";
import {
    BaseInfo, ChoiceInfo,
    CommonAttrInfo,
    LinkageUserData,
    MailMagazineMensMagazine,
    ManageInfo,
    MemberAgreementInfo,
    ShippingInfo,
    SiteInfo,
    UserBase,
    UserMensMagazine,
} from "@/commons/interfaces";
import { InjectionKey, reactive } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import MensMagazineProfile from "@/components/Linkage/Sites/MensMagazineProfile.vue";
import MensMagazineConfirm from "@/components/Linkage/Sites/MensMagazineConfirm.vue";
import MensMagazineWithdrawCustom from "@/components/Linkage/Sites/MensMagazineWithdrawCustom.vue";

// メンズマガジン関連定数定義
/** 連携ID */
export const MENS_MAGAZINE_LINKAGE_ID = 9;
/** サイト固有情報APIパス */
export const MENS_MAGAZINE_SITE_UNIQ_API_PATH = "/mens_magazine/me";
/** デフォルトコールバックURL */
/** 基本属性情報入力 */
export const MENS_MAGAZINE_PROFILE_COMPONENT = MensMagazineProfile;
/** 基本属性情報確認 */
export const MENS_MAGAZINE_CONFIRM_COMPONENT = MensMagazineConfirm;
/** 退会画面カスタムコンポーネント */
export const MENS_MAGAZINE_WITHDRAW_CUSTOM_COMPONENT = MensMagazineWithdrawCustom;

/**
 * メンズマガジン連携画面用基本属性情報管理クラス
 */
export class MensMagazineUserBase implements LinkageUserData<UserBase> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> = Symbol("MensMagazineUserBase");

    /** 基本属性情報 */
    public readonly data: UserBase = reactive(new UserBase());
    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserBase());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserBase {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserBase): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.baseInfo = Object.assign(new BaseInfo(), obj.baseInfo);
        this.data.shippingInfo = obj.shippingInfo?.map(inf => Object.assign(new ShippingInfo(), inf)) || [];
        this.data.siteInfo = obj.siteInfo?.map(inf => Object.assign(new SiteInfo(), inf)) || [];
        this.data.commonAttrInfo = Object.assign(new CommonAttrInfo(), obj.commonAttrInfo);
        this.data.memberAgreeInfo = Object.assign(new MemberAgreementInfo(), obj.memberAgreeInfo);
        this.data.manageInfo = Object.assign(new ManageInfo(), obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.getUserAttr()
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserBase();   
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // 基本属性情報の有無
        if (!this.data.identityId) return false;
        // 登録者情報
        if (!this.data.baseInfo.firstName) return false;
        if (!this.data.baseInfo.lastName) return false;
        // if (!this.data.baseInfo.firstKana) return false;
        // if (!this.data.baseInfo.lastKana) return false;
        // if (!this.data.baseInfo.zipCode) return false;
        // if (!this.data.baseInfo.prefecture) return false;
        // if (!this.data.baseInfo.address) return false;
        // if (!this.data.baseInfo.building) return false;
        if (!this.data.baseInfo.email) return false;
        if (!this.data.baseInfo.tel) return false;
        // 属性情報
        if (!this.data.commonAttrInfo.gender) return false;
        if (!this.data.commonAttrInfo.birthday) return false;
        // if (!this.data.commonAttrInfo.imagePath) return false;
        // if (!this.data.commonAttrInfo.nickName) return false;
        // if (this.data.commonAttrInfo.isMarried === null || this.data.commonAttrInfo.isMarried === undefined) return false;
        // if (this.data.commonAttrInfo.hasChild === null || this.data.commonAttrInfo.hasChild === undefined) return false;
        // if (!this.data.commonAttrInfo.bloodType) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> {
        return MensMagazineUserBase.injectionKey;
    }

}

/**
 * メンズマガジン連携画面用サイト固有情報管理クラス
 */
export class MensMagazineSiteUniq implements LinkageUserData<UserMensMagazine> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserMensMagazine>>> = Symbol("MensMagazineSiteUniq");

    /** メンズマガジンサイト固有情報 */
    public readonly data: UserMensMagazine = reactive(new UserMensMagazine());

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserMensMagazine());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserMensMagazine {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserMensMagazine): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.job = obj.job;
        this.data.income = obj.income;
        this.data.drivingLicence = obj.drivingLicence;
        this.data.isSmoking = obj.isSmoking;
        this.data.drinking = obj.drinking;
        this.data.hobby = obj.hobby;
        this.data.hobbyOther = obj.hobbyOther;
        this.data.interestSports = obj.interestSports;
        this.data.spendHolidays = obj.spendHolidays;
        this.data.favoriteBrands = obj.favoriteBrands;
        this.data.favoriteBrandOther = obj.favoriteBrandOther;
        this.data.favoriteCosmes = obj.favoriteCosmes;
        this.data.favoriteHairCares = obj.favoriteHairCares;
        this.data.mailMagazine = Object.assign(new MailMagazineMensMagazine(), obj.mailMagazine);
        this.data.manageInfo = Object.assign({}, obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.requestApi({ method: "GET", path: MENS_MAGAZINE_SITE_UNIQ_API_PATH })
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserMensMagazine();
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の有無
        if (this.data.job === null || this.data.job === undefined) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserMensMagazine>>> {
        return MensMagazineSiteUniq.injectionKey;
    }
}

export const JOB_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "官公庁・公務員" },
    { value: 1, text: "メーカー・商社" },
    { value: 2, text: "金融・銀行" },
    { value: 3, text: "農林水産業" },
    { value: 4, text: "建設関係" },
    { value: 5, text: "交通・流通" },
    { value: 6, text: "ＩＴ・通信" },
    { value: 7, text: "マスコミ・広告" },
    { value: 8, text: "エネルギー関連" },
    { value: 9, text: "弁護士・会計士・司法関連" },
    { value: 10, text: "医療（病院・介護施設・薬品）" },
    { value: 11, text: "教育・学術・研究機関" },
    { value: 12, text: "自営業" },
    { value: 13, text: "自由業・フリーランス" },
    { value: 14, text: "アルバイト、パート" },
    { value: 15, text: "学生（大学院・大学・短大）" },
    { value: 16, text: "学生（小・中・高）" },
    { value: 17, text: "学生（専門学校・各種学校）" },
    { value: 18, text: "主婦・主夫" },
    { value: 19, text: "無職" },
    { value: 99, text: "そのほか" },
];

export const INCOME_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "300万円以下" },
    { value: 1, text: "500万円以下" },
    { value: 2, text: "700万円以下" },
    { value: 3, text: "1000万円以下" },
    { value: 4, text: "1000万円以上" },
];

export const DRIVING_LICENCE_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "有り" },
    { value: 1, text: "無し" },
    { value: 2, text: "できれば取得したい" }
];

export const IS_SMOKING_LIST: ChoiceInfo<boolean>[] = [
    { value: null, text: "選択してください" },
    { value: true, text: "喫煙する" },
    { value: false, text: "喫煙しない" }
];

export const DRINKING_LIST: ChoiceInfo<number>[] = [
    { value: null, text: "選択してください" },
    { value: 0, text: "週２～３回" },
    { value: 1, text: "月１回程度" },
    { value: 2, text: "毎日" },
    { value: 3, text: "飲まない" }
];

export const HOBBY_LIST: string[] = [
    "野球",
    "サッカー・フットサル",
    "テニス",
    "バレーボール",
    "バスケットボール",
    "釣り",
    "卓球",
    "柔道・剣道",
    "ラグビー",
    "ボクシング",
    "サーフィン・ウィンドサーフィン",
    "ダイビング",
    "スキー・スノボ",
    "スケート",
    "ゴルフ",
    "水泳",
    "陸上競技",
    "ジョギング・ランニング",
    "スポーツジム・トレーニング",
    "空手",
    "相撲",
    "自転車",
    "オートバイ",
    "ダンス",
    "ビリヤード",
    "インターネット",
    "ゲーム",
    "カラオケ・ボイストレーニング",
    "音楽鑑賞",
    "舞台鑑賞",
    "楽器演奏",
    "プラモデル",
    "囲碁・将棋",
    "読書",
    "漫画",
    "美術・イラスト",
    "登山・ハイキング",
    "写真",
    "英会話・語学",
    "料理・お菓子作り",
    "競馬",
    "競輪",
    "パチンコ・パチスロ",
    "麻雀",
    "映画鑑賞",
    "ドライブ",
    "旅行",
    "ファッション",
    "グルメ",
    "その他",
];

export const INTEREST_SPORTS_LIST: string[] = [
    "野球(プロ野球、学生・アマチュア野球)",
    "MLB",
    "サッカー日本代表",
    "Jリーグ",
    "海外サッカー",
    "ゴルフ",
    "モータースポーツ（F1、MotoGPほか）",
    "NBA、国内バスケットボール",
    "NFL、アメリカンフットボール",
    "バレーボール",
    "テニス",
    "ラグビー",
    "フットサル",
    "卓球",
    "水泳",
    "陸上",
    "柔道",
    "フィギュアスケート",
    "冬季競技(スキー、スピードスケート、ジャンプほか)",
    "競馬",
    "大相撲",
    "格闘技(ボクシング、プロレス)",
    "そのほか",
];

export const SPEND_HOLIDAYS_LIST: string[] = [
    "スポーツ観戦",
    "国内旅行",
    "海外旅行",
    "映画・DVD鑑賞",
    "読書",
    "音楽",
    "ドライブ",
    "自転車",
    "ジョギング、ウォーキング",
    "スポーツジム、トレーニング",
    "野球",
    "サッカー、フットサル",
    "テニス",
    "ゴルフ",
    "そのほかスポーツ",
    "競馬",
    "公営競技(競馬を除く)",
    "ショッピング",
    "インターネット",
    "自宅休養",
    "そのほか",
];

export const FAVORITE_BRANDS_LIST: string[] = [
    "A.P.C.",
    "BEAMS",
    "DIESEL",
    "GAP",
    "hare",
    "LAD MUSICIAN",
    "MR.GENTLEMAN",
    "N.ハリウッド",
    "R.NEWBOLD",
    "WEGO",
    "YAECA",
    "シュプリーム",
    "ユニバーサル プロダクツ",
    "エンポリオ アルマーニ",
    "コムデギャルソン",
    "ディオール オム",
    "バーバリー ブラックレーベル",
    "ポール･スミス",
    "ユナイテッドアローズ",
    "ユニクロ",
    "その他",
];

export const FAVORITE_COSMES_LIST: string[] = [
    "アベンヌ",
    "アヴェダ",
    "イソップ",
    "イプサ",
    "ＵＮＯ",
    "エテュセオム",
    "カルバンクライン",
    "キールズ",
    "ギャツビー",
    "クリニークフォーメン",
    "資生堂メン",
    "ジバンシィ",
    "ジョンマスターオーガニック",
    "シャネル",
    "ＴＨＲＥＥ",
    "ＤＨＣ",
    "ディオール",
    "ドクターシーラボ",
    "トム フォード",
    "ニベアメン",
    "バルクオム",
    "ブルガリ",
    "ザ・ボディショップ",
    "メンズビオレ",
    "ヴェレダ",
    "ミノンメン",
    "ラボシリーズ",
    "ルシード",
    "ロクシタン",
    "その他",
];

export const FAVORITE_HAIR_CARES_LIST: string[] = [
    "アヴェダ",
    "イソップ",
    "ウエラ",
    "ＵＮＯ",
    "ウル・オス",
    "エッセンシャル",
    "キールズ",
    "ギャツビー",
    "ケラステーゼ",
    "サンスター",
    "シーブリーズ",
    "資生堂メン",
    "ジョンマスターオーガニック",
    "スカルプＤ",
    "ＴＨＲＥＥ",
    "ダヴ",
    "ヴェレダ",
    "ザ・ボディショップ",
    "マンダム",
    "モロッカンオイル",
    "ラボシリーズ",
    "リアップ",
    "ルシード",
    "ロレアル",
    "その他",
];
