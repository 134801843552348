import { inject, InjectionKey, provide } from "vue";
import { SidUserClient } from "@sid/sid_client";
import { LinkageRouteNames } from "@/commons/linkage/linkageCommon";
import { reEncodeUriComponent } from "@/commons/commonUtility";

/** LinkageItemインジェクションキー */
const LinkageItemKey: InjectionKey<LinkageItem> = Symbol("LinkageItem");

/** LinkageInfoインタフェース */
interface LinkageInfo {
    /** 連携ID */
    linkageId: number;
    /** 連携名 */
    linkageName: string;
    /** 連携パス */
    pathName: string;
    /** サイト固有情報APIパス */
    siteUniqApiPath: string;
    /** 指定されたコードチャレンジ */
    codeChallenge: string;
    /** 指定されたコールバックURL */
    callbackUrl: string;
    /** 指定されたメールアドレス */
    email: string | null;
    /** 発行したトークン付きコールバックURL */
    callbackUrlWithToken: string | null;
    /** 連携サブID */
    linkageSubId: number | null;
    /** サイトでログイン状態を使用しない */
    loginStateUnused: boolean;
}

/** 連携情報 */
export class LinkageItem {

    /** 連携情報 */
    public readonly linkageInfo: LinkageInfo = {
        linkageId: -1,
        linkageName: "",
        pathName: "",
        siteUniqApiPath: "",
        codeChallenge: "",
        callbackUrl: "",
        email: null,
        callbackUrlWithToken: null,
        linkageSubId: null,
        loginStateUnused: false,
    };

    /** ルート情報 */
    public readonly routeNames: LinkageRouteNames;
    /** サイト固有情報アクセスAPIパス */
    public readonly siteUniqApiPath: string;
    /** デフォルトコールバックURL */
    public readonly siteDefaultCallbackUrl: string;
    /** サービス規約URL */
    public readonly serviceTermUrl: string;
    /** メールアドレスドメイン */
    public readonly emailDomains: Array<string>;

    /**
     * インスタンス生成
     * @param linkageId
     * @param linkageName
     * @param pathName
     * @param apiPath
     * @param defaultUrl
     * @param termUrl
     * @param emailDomains
     */
    public static create(linkageId: number, linkageName: string, pathName: string, apiPath: string, defaultUrl: string, termUrl: string, emailDomains: Array<string>, linkageSubId?: number, loginStateUnused?: boolean) {
        return new LinkageItem(linkageId, linkageName, pathName, apiPath, defaultUrl, termUrl, emailDomains, linkageSubId, loginStateUnused);
    }

    /**
     * インジェクション
     */
    public static inject(): LinkageItem {
        const info = inject(LinkageItemKey);
        if (info) {
            return info;
        }
        throw new Error("not provided.");
    }

    /**
     * コンストラクタ
     * @param linkageId
     * @param linkageName
     * @param apiPath
     * @param defaultUrl
     * @param termUrl
     * @param emailDomains
     */
    public constructor(linkageId: number, linkageName: string, pathName: string, apiPath: string, defaultUrl: string, termUrl: string, emailDomains: Array<string>, linkageSubId?: number, loginStateUnused?: boolean) {
        this.linkageInfo.linkageId = linkageId;
        this.linkageInfo.linkageName = linkageName;
        this.linkageInfo.pathName = pathName;
        this.routeNames = new LinkageRouteNames(linkageId, linkageName);
        this.siteUniqApiPath = apiPath;
        this.siteDefaultCallbackUrl = defaultUrl;
        this.serviceTermUrl = termUrl;
        this.emailDomains = emailDomains;
        this.linkageInfo.linkageSubId = linkageSubId ?? null;
        this.linkageInfo.loginStateUnused = loginStateUnused ?? false;
        this.loadStorage();
    }

    /**
     * 連携情報設定
     * @param info
     */
    public setLinkageInfo(info: LinkageInfo) {
        this.linkageInfo.linkageId = info.linkageId;
        this.linkageInfo.linkageName = info.linkageName;
        this.linkageInfo.pathName = info.pathName;
        this.linkageInfo.siteUniqApiPath = info.siteUniqApiPath;
        this.linkageInfo.codeChallenge = info.codeChallenge;
        this.linkageInfo.callbackUrl = info.callbackUrl;
        this.linkageInfo.email = info.email;
        this.linkageInfo.callbackUrlWithToken = info.callbackUrlWithToken;
        this.linkageInfo.linkageSubId = info.linkageSubId;
        this.linkageInfo.loginStateUnused = info.loginStateUnused;
    }

    /**
     * ユーザトークン取得
     * @param sidClient
     * @param force
     */
    public fetchToken = async (sidClient: SidUserClient, force = false) => {
        if (this.linkageInfo.loginStateUnused) {
            return;
        }
        if (this.linkageInfo.callbackUrlWithToken && !force) {
            return;
        }
        const response = await sidClient.requestApi({
            method: "GET",
            path: "/linkage/token",
            queries: {
                "link_id": `${this.linkageInfo.linkageId}`,
                "code_challenge": this.linkageInfo.codeChallenge,
                "callback": reEncodeUriComponent(this.linkageInfo.callbackUrl)
            }
        });
        this.linkageInfo.callbackUrlWithToken = response.data.callback;
    }

    /**
     * このインスタンスをprovide()する
     */
    public provide() {
        provide(LinkageItemKey, this);
    }

    /**
     * セッションストレージから読み出す
     */
    public loadStorage() {
        if (sessionStorage[this.storageKey])
            this.setLinkageInfo(JSON.parse(sessionStorage[this.storageKey]));
    }

    /**
     * セッションストレージに格納
     */
    public storeStorage() {
        sessionStorage[this.storageKey] = JSON.stringify(this.linkageInfo);
    }

    /**
     * セッションストレージから削除
     */
    public removeStorage() {
        sessionStorage.removeItem(this.storageKey);
    }

    /**
     * セッションストレージへの格納キー
     */
    get storageKey() {
        return `${this.linkageInfo.linkageName}LinkageInfo`;
    }
}
