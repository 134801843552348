
import { defineComponent, ref, PropType, watch, toRefs } from "vue";

export default defineComponent({
    name: "NullableSelect",
    props: {
        modelValue: {
            type: [String, Number, Boolean, null] as PropType<string | number | boolean | null>,
            default: null,
            required: true,
        },
        options: {
            type: Array<{ text: string; value: string | null } | { text: string; value: number | null } | { text: string; value: boolean | null }>,
            default: () => []
        },
        type: {
            type: String,
            default: "string",
            validator: (val: string) => ["string", "number", "boolean"].includes(val)
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const selected = ref(props.modelValue !== null ? props.modelValue.toString() : "null");
        const { modelValue } = toRefs(props);

        watch(modelValue, (newVal) => {
            selected.value = newVal !== null ? newVal.toString() : "null";
        });

        watch(selected, (newVal) => {
            let emitVal: string | number | boolean | null;
            if (newVal === "null") {
                emitVal = null;
            } else if (props.type === "number") {
                emitVal = Number(newVal);
            } else if (props.type === "boolean") {
                emitVal = newVal === "true";
            } else {
                emitVal = newVal;
            }
            emit("update:modelValue", emitVal);
        });

        return {
            selected
        };
    }
});
