import moment from "moment";
import { computed, reactive, ref, watch } from "vue";

/**
 * 日付フォーム用
 */
class DateForm {
    public readonly startYear: number;

    constructor (startYear: number) {
        this.startYear = startYear;
        watch(this.date, () => {
            this.setBiethday();
            this.modify();
        });
    }

    /**
     * 日付オブジェクト(初期値はNaN)
     */
    public readonly date = reactive<{ year: number; month: number; day: number }>({
        year: NaN,
        month: NaN,
        day: NaN
    });

    public readonly birthday = ref<string>("");

    /**
     * 誕生日設定
     */
    public setBiethday() {
        this.birthday.value = isNaN(this.date.year) && isNaN(this.date.month) && isNaN(this.date.day) ? "" : `${this.date.year}-${this.date.month}-${this.date.day}`;
    }

    /**
     * 年設定
     * @param initialYear
     */
    public setYear = (initialYear: number) => {
        this.date.year = (initialYear > this.startYear) ? initialYear : NaN;
    }

    /**
     * 日付取得
     */
    public getDate = () => {
        return this.date;
    }

    /**
     * 日付設定
     * @param dateStr 日付(YYYY-M-D形式)
     */
    public setDate = (dateStr: string) => {
        const dateObj = moment(dateStr, "YYYY-M-D", true);
        if (dateObj.isValid()) {
            this.date.year = dateObj.year();
            this.date.month = dateObj.month() + 1;
            this.date.day = dateObj.date();
        }
    }

    /**
     * 月末日取得
     * @param year 選択年
     * @param month 選択月
     */
    private getFinalDate = (year: number, month: number) => {
        // 月末日
        return moment([year, month - 1]).endOf("month").date();
    }

    /**
    * 末日調整
    */
    private modify = () => {
        if (!isNaN(this.date.year) && !isNaN(this.date.month) && !isNaN(this.date.day)) {
            // 年や月が変更されたとき、日が存在しなくなる場合があるので調整する
            // 例: 2018-12-31 を選択していて月が 12 から 2 に変更された場合、日を 28 にする
            if (!moment([this.date.year, this.date.month - 1, this.date.day], true).isValid()) {
                this.date.day = this.getFinalDate(this.date.year, this.date.month);
            }
        }
    }

    /**
     * 選択肢：年
     * @returns
     */
    public years = computed(() => {
        const currentYear = moment().year();
        const goBackYears = currentYear - this.startYear;
        return [...Array(goBackYears + 1).keys()].map(x => x + this.startYear);
    });

    /**
     * 選択肢：月
     */
    public months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    /**
     * 選択肢：日
     * @param year 
     * @param month 
     * @returns 
     */
    public dates = computed(() => {
        let maxDate = 31;
        if (!isNaN(this.date.year) && !isNaN(this.date.month)) {
            maxDate = this.getFinalDate(this.date.year, this.date.month);
        }
        return [...Array(maxDate).keys()].map(x => x + 1);
    });
}

export function useDateForm (startYear = 1900){ return new DateForm(startYear); }