import { inject, InjectionKey, provide } from "vue";
import { LinkageUserData } from "@/commons/interfaces";
import { SidUserClient } from "@sid/sid_client";


/**
 * 連携ユーザ情報
 * <pre>
 * 連携画面で扱うユーザ情報（基本属性情報・サイト固有情報）を提供する
 * </pre>
 */
export class LinkageUserItem<T extends LinkageUserData<any>> {

    /**
     * インスタンスを作成
     * @param klass ユーザ情報の型(LinkageUserDataサブクラス)
     */
    public static create(klass: { new(): any }) {
        return new LinkageUserItem(klass);
    }

    /**
     * インジェクション
     * <pre>
     * ※親のVueコンポーネントでprovide()されていない場合例外をスローする
     * </pre>
     * @param key
     */
    public static inject(key: InjectionKey<LinkageUserItem<LinkageUserData<any>>>) {
        const info = inject(key);
        if (info) {
            return info;
        }
        throw new Error("not provided.");
    }

    /**
     * ユーザ情報管理クラスのインスタンス
     * @private
     */
    private readonly userDataInstance: T;

    /**
     * ユーザ情報管理クラスの型
     * @private
     */
    private readonly klass: new() => T;

    /**
     * コンストラクタ
     * @param klass ユーザ情報管理クラスの型
     */
    public constructor(klass: new() => T) {
        this.klass = klass;
        this.userDataInstance = new klass();
        this.loadStorage();
    }

    /**
     * ユーザ情報
     * <pre>
     * ユーザ情報管理クラスが持つユーザ情報へのプロキシ
     * </pre>
     */
    get userData() {
        return this.userDataInstance.getData();
    }

    /**
     * ユーザ情報
     * <pre>
     * ユーザ情報管理クラスが持つユーザ情報へのプロキシ
     * </pre>
     * @param obj
     */
    set userData(obj) {
        this.userDataInstance.copy(obj);
    }

    /**
     * ユーザ情報初期化
     */
    public initializeUserData() {
        this.userDataInstance.initData();
    }

    /**
     * ユーザ情報を取得格納
     * @param sidClient
     */
    public async fetchUserData(sidClient: SidUserClient) {
        return this.userDataInstance.fetch(sidClient);
    }

    /**
     * ユーザ情報の必須チェック
     */
    public checkRequired() {
        return this.userDataInstance.checkRequired();
    }

    /**
     * このインスタンスをprovide()する
     */
    public provide() {
        provide(this.userDataInstance.getInjectKey(), this);
    }

    /**
     * セッションストレージから読み出す
     */
    public loadStorage() {
        if (sessionStorage[this.storageKey])
            this.userDataInstance.copy(JSON.parse(sessionStorage[this.storageKey]));
    }

    /**
     * セッションストレージに格納
     */
    public storeStorage() {
        sessionStorage[this.storageKey] = JSON.stringify(this.userDataInstance.getData());
    }

    /**
     * セッションストレージを削除
     */
    public removeStorage() {
        sessionStorage.removeItem(this.storageKey);
    }

    /**
     * セッションストレージへの格納キー
     */
    get storageKey() {
        return this.klass.name;
    }
}
