import { SidUserClient } from "@sid/sid_client";
import { BaseInfo, CommonAttrInfo, LinkageUserData, ManageInfo, MemberAgreementInfo, ServiceAgreeInfoLee, ShippingInfo, SiteInfo, UserBase, UserLee } from "@/commons/interfaces";
import { InjectionKey, reactive } from "vue";
import { LinkageUserItem } from "@/modules/linkage/linkageUserItem";
import LeeProfile from "@/components/Linkage/Sites/LeeProfile.vue";
import LeeConfirm from "@/components/Linkage/Sites/LeeConfirm.vue";
import LeeLoginCustom from "@/components/Linkage/Sites/LeeLoginCustom.vue";

// LEE関連定数定義
/** 連携ID */
export const LEE_LINKAGE_ID = 5;
/** 連携名称 */
export const LEE_LINKAGE_NAME = "Lee";
/** 連携パス */
export const LEE_PATH = LEE_LINKAGE_NAME.toLowerCase();
/** サイト固有情報APIパス */
export const LEE_SITE_UNIQ_API_PATH = "/lee/me";
/** デフォルトコールバックURL */
export const LEE_DEFAULT_CALLBACK_URL = process.env.VUE_APP_LEE_DEFAULT_CALLBACK_URL;
/** 許容コールバックURL */
export const LEE_ACCEPT_CALLBACK_URLS = process.env.VUE_APP_LEE_ACCEPT_CALLBACK_URLS.split(",").map((url: string) => `^${url}(\\?.*)?$`);
/** サービス規約URL */
export const LEE_SERVICE_TERM_URL = process.env.VUE_APP_LEE_SERVICE_TERM_URL;
/** サービス規約合意情報バージョン */
export const LEE_SERVICE_AGREE_INFO_VERSION = 0;
/** メールアドレスドメイン */
export const LEE_EMAIL_DOMAINS = [
    "hpplus.jp"
];
/** 基本属性情報入力 */
export const LEE_PROFILE_COMPONENT = LeeProfile;
/** 基本属性情報確認 */
export const LEE_CONFIRM_COMPONENT = LeeConfirm;
/** ログイン画面カスタムパーツ */
export const LEE_LOGIN_CUSTOM_COMPONENT = LeeLoginCustom;

/**
 * LEE連携画面用基本属性情報管理クラス
 */
export class LeeUserBase implements LinkageUserData<UserBase> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> = Symbol("LeeUserBase");

    /** 基本属性情報 */
    public readonly data: UserBase = reactive(new UserBase());
    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserBase());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserBase {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserBase): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.baseInfo = Object.assign(new BaseInfo(), obj.baseInfo);
        this.data.shippingInfo = obj.shippingInfo?.map(inf => Object.assign(new ShippingInfo(), inf)) || [];
        this.data.siteInfo = obj.siteInfo?.map(inf => Object.assign(new SiteInfo(), inf)) || [];
        this.data.commonAttrInfo = Object.assign(new CommonAttrInfo(), obj.commonAttrInfo);
        this.data.memberAgreeInfo = Object.assign(new MemberAgreementInfo(), obj.memberAgreeInfo);
        this.data.manageInfo = Object.assign(new ManageInfo(), obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.getUserAttr()
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserBase();   
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // 基本属性情報の有無
        if (!this.data.identityId) return false;
        // 登録者情報
        // if (!this.data.baseInfo.firstName) return false;
        // if (!this.data.baseInfo.lastName) return false;
        // if (!this.data.baseInfo.firstKana) return false;
        // if (!this.data.baseInfo.lastKana) return false;
        // if (!this.data.baseInfo.zipCode) return false;
        if (!this.data.baseInfo.prefecture) return false;
        // if (!this.data.baseInfo.address) return false;
        // if (!this.data.baseInfo.building) return false;
        if (!this.data.baseInfo.email) return false;
        // if (!this.data.baseInfo.tel) return false;
        // 属性情報
        // if (!this.data.commonAttrInfo.gender) return false;
        if (!this.data.commonAttrInfo.birthday) return false;
        // if (!this.data.commonAttrInfo.imagePath) return false;
        // if (!this.data.commonAttrInfo.nickName) return false;
        // if (this.data.commonAttrInfo.isMarried === null || this.data.commonAttrInfo.isMarried === undefined) return false;
        // if (this.data.commonAttrInfo.hasChild === null || this.data.commonAttrInfo.hasChild === undefined) return false;
        // if (!this.data.commonAttrInfo.bloodType) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserBase>>> {
        return LeeUserBase.injectionKey;
    }

}

/**
 * LEE連携画面用サイト固有情報管理クラス
 */
export class LeeSiteUniq implements LinkageUserData<UserLee> {

    /** インジェクションキー */
    public static injectionKey: InjectionKey<LinkageUserItem<LinkageUserData<UserLee>>> = Symbol("UserLee");

    /** LEEサイト固有情報 */
    public readonly data: UserLee = reactive(new UserLee());

    /**
     * @inheritDoc LinkageUserData
     */
    public initData(): void {
        this.copy(new UserLee());
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public getData(): UserLee {
        return this.data;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public copy(obj: UserLee): void {
        this.data.identityId = obj.identityId;
        this.data.userName = obj.userName;
        this.data.job = obj.job;
        this.data.nickName = obj.nickName;
        this.data.isSendMailMagazine = obj.isSendMailMagazine;
        this.data.serviceAgreeInfo = Object.assign(new ServiceAgreeInfoLee(), obj.serviceAgreeInfo);
        this.data.manageInfo = Object.assign({}, obj.manageInfo);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public async fetch(sidClient: SidUserClient): Promise<void> {
        const response = await sidClient.requestApi({ method: "GET", path: LEE_SITE_UNIQ_API_PATH })
            .then(res => res.data)
            .catch(res => {
                if (res.response?.status === 404) {
                    return new UserLee();   
                } else {
                    throw new Error("Failed to get information.");
                }
            });
        this.copy(response);
    }

    /**
     * @inheritDoc LinkageUserData
     */
    public checkRequired(): boolean {
        // サイト固有情報の有無
        if (!this.data.job) return false;
        if (!this.data.nickName) return false;
        if (this.data.isSendMailMagazine === null || this.data.isSendMailMagazine === undefined) return false;
        return true;
    }

    /**
     * @inheritDoc LinkageUserData
     */
    getInjectKey(): InjectionKey<LinkageUserItem<LinkageUserData<UserLee>>> {
        return LeeSiteUniq.injectionKey;
    }
}
