import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt, idx) => {
        return (_openBlock(), _createElementBlock("option", {
          key: idx,
          value: opt.value != null ? opt.value.toString() : 'null'
        }, _toDisplayString(opt.text), 9, _hoisted_1))
      }), 128))
    ], 512), [
      [_vModelSelect, _ctx.selected]
    ])
  ]))
}