
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { Form, Field } from "vee-validate";
import { checkEmail } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";

export default defineComponent({
    name: "ResetPassword",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const emailRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            email: checkEmail
        });

        /**
         * パスワードリセット要求
         * @async
         */
        const forgotPassword = async() => {
            await sidClient.forgotPassword(emailRef.value)
                .then(() => {
                    router.push({ name: "SetPassword", query: { email: emailRef.value } });
                })
                .catch(() => {
                    errorMsgRef.value = "お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
                });
        };

        onMounted((): void => {
            if (route.query.email) {
                emailRef.value = route.query.email as string;
            }
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            emailRef,
            errorMsgRef,
            schema,
            forgotPassword
        };
    },
});
