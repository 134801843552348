
import { defineComponent, ref } from "vue";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isSiteRegistered } from "@/commons/commonUtility";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";

export default defineComponent({
    name: "LinkageLoginProfile",
    props: {
        message: { type: String },
    },
    setup(props) {
        const { linkageInfo } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        // タイトル文言判定
        const title = !userBaseItem.userData.identityId ? "会員情報登録" :
            (isSiteRegistered(linkageInfo.linkageId, userBaseItem.userData) ? "会員情報更新" : "会員情報の追加");
        const errorMsgRef = ref(props.message);
        const infoMsgRef = ref("");

        // サイト登録有無・必須情報チェック
        if (!isSiteRegistered(linkageInfo.linkageId, userBaseItem.userData)
            || !userBaseItem.checkRequired()
            || !siteUniqItem.checkRequired()) {
            infoMsgRef.value = "ご利用にあたり登録情報の確認をお願いいたします。";
        }

        return {
            title,
            errorMsgRef,
            infoMsgRef
        };
    }
});
