
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, inject, onMounted, ref } from "vue";
import { CognitoItem, CognitoItemKey } from "@/modules/cognitoItem";
import { Field, Form } from "vee-validate";
import { checkConfirmationCode } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { loginStatus } from "@/modules/loginStatus";
import LinkageSignupProgress from "@/components/Linkage/LinkageSignupProgress.vue";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import { reEncodeUriComponent } from "@/commons/commonUtility";

export default defineComponent({
    name: "LinkageSignupCode",
    components: {
        Form,
        Field,
        LinkageSignupProgress
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        const { linkageInfo, routeNames } = LinkageItem.inject();
        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);

        const { cognitoInfo } = inject(CognitoItemKey) as CognitoItem;
        const confirmationCodeRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            confirmationCode: checkConfirmationCode
        });

        /**
         * 送信ボタン押下時処理
         * @async
         */
        const confirmConfirmationCode = async () => {
            try {
                // 確認コード検証
                await sidClient.confirmation(cognitoInfo.userName, confirmationCodeRef.value);

                if (cognitoInfo.password === "") {
                    // メールのリンクから飛んできた場合、passwordがセットされていないので、本人確認画面へ遷移
                    await router.push({ name: routeNames.SIGNUP_IDENTIFICATION, query: { retUrl: `${routeNames.SIGNUP_PROFILE}Child` } });
                    return;
                }

                // 念のため、サインアウト
                await sidClient.signOut();
                loginStatus.value = false;

                // セッションストレージ削除
                userBaseItem.removeStorage();
                siteUniqItem.removeStorage();

                // ログイン
                await sidClient.signIn(cognitoInfo.userName, cognitoInfo.password, true);
                const userName = (await sidClient.getUserData()).Username;

                // ユーザ情報初期化
                userBaseItem.initializeUserData();
                siteUniqItem.initializeUserData();

                // ユーザ名格納
                userBaseItem.userData.userName = userName;
                siteUniqItem.userData.userName = userName;

                // セッションストレージ格納
                userBaseItem.storeStorage();
                siteUniqItem.storeStorage();

                // 基本属性情報登録画面へ遷移
                await router.push({ name: `${routeNames.SIGNUP_PROFILE}Child` });
            } catch (e) {
                if (e.code === "ExpiredCodeException") {
                    // メールアドレス確認済の場合
                    errorMsgRef.value = e.code;
                } else if (e.code === "AliasExistsException") {
                    // メールアドレスが既にCognitoに存在する(メールアドレスが既に他ユーザに使用されている)場合
                    errorMsgRef.value = e.code;
                } else {
                    errorMsgRef.value = "コードが確認できませんでした。正しいコードを入力して再実施ください。";
                }
            }
        };

        /**
         * 確認コード再送ボタン押下時処理
         * @async
         */
        const resendConfirmationCode = async () => {
            try {
                // 確認コード再送
                await sidClient.resendConfirmationCode(
                    cognitoInfo.userName,
                    {
                        LinkageName: linkageInfo?.pathName,
                        CodeChallenge: linkageInfo?.codeChallenge,
                        CallbackUrl: reEncodeUriComponent(linkageInfo?.callbackUrl)
                    }
                );
            } catch (e) {
                errorMsgRef.value = "エラーが発生しました。お手数をおかけしますが、しばらく時間を置いてから、再度操作していただけますようお願いいたします。";
            }
        };

        onMounted(async () => {
            // 検証コードメール内に記載されているリンクから飛んできた場合
            // param:username, query:code
            if (route.params.username && route.query.code) {
                cognitoInfo.userName = route.params.username as string;
                confirmationCodeRef.value = route.query.code as string;
            }
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            confirmationCodeRef,
            errorMsgRef,
            schema,
            confirmConfirmationCode,
            resendConfirmationCode,
            routeNames
        };
    },
});
