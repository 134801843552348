
import { useSidClient } from "@/plugins/auth";
import { useRouter } from "vue-router";
import { defineComponent, reactive, ref } from "vue";
import { SID_ERROR_MSG_NOT_AUTHENTICATED, SID_ERROR_MSG_UNAUTHORIZED } from "@sid/sid_client";
import { Form, Field } from "vee-validate";
import { checkOldPassword, checkNewPassword, checkNewPasswordRetype } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { PASSWORD_MAX_LENGTH } from "@/commons/constants";
import useDeterring from "@/commons/useDeterring";

export default defineComponent({
    name: "ChangePassword",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();
        
        const oldPasswordRef = ref("");
        const newPasswordRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            oldPassword: checkOldPassword,
            newPassword: checkNewPassword,
            newPasswordRetype: checkNewPasswordRetype
        });

        const oldPasswordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const newPasswordToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        const newPasswordRetypeToggle = reactive<{
            fieldType: string;
            isOn: boolean;
        }>({
            fieldType: "password",
            isOn: false
        });

        /**
         * パスワード表示・非表示切替
         * @param toggle { fieldType: string; isOn: boolean } 
         */
        const switchPasswordFieldType = (toggle: { fieldType: string; isOn: boolean }) => {
            if (toggle.fieldType === "password") {
                toggle.fieldType = "text";
                toggle.isOn = true;
            } else {
                toggle.fieldType = "password";
                toggle.isOn = false;
            }
            return toggle;
        };
        
        /**
         * 変更ボタン押下時処理
         * @async
         */
        const changePassword = async () => {
            // パスワード更新
            await sidClient.changePassword(oldPasswordRef.value, newPasswordRef.value)
                .then(() => {
                    // パスワード変更完了画面へ遷移
                    router.push({ name: "ChangePasswordComplete" });
                })
                .catch(reason => {
                    if (reason.message === SID_ERROR_MSG_NOT_AUTHENTICATED || reason.message === SID_ERROR_MSG_UNAUTHORIZED) {
                        // ログイン画面へ遷移
                        router.push({ name: "Login" });
                    } else {
                        errorMsgRef.value = "パスワード変更に失敗しました。後ほど再度お試し下さい。";
                    }
                });
        };

        return {
            isDeterringRef,
            asyncDeterringWrap,
            oldPasswordRef,
            newPasswordRef,
            errorMsgRef,
            schema,
            PASSWORD_MAX_LENGTH,
            oldPasswordToggle,
            newPasswordToggle,
            newPasswordRetypeToggle,
            switchPasswordFieldType,
            changePassword
        };
    },
});
