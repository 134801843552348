import { InjectionKey, reactive } from "vue";

export const createLeaveNoteItem = () => {
    const leaveNote = reactive<{
        leaveNote1: string | undefined;
        leaveNote2: string | undefined;
    }>({
        leaveNote1: undefined,
        leaveNote2: undefined
    });

    return {
        leaveNote
    };
};

export type LeaveNoteItem = ReturnType<typeof createLeaveNoteItem>;

export const LeaveNoteItemKey: InjectionKey<LeaveNoteItem> = Symbol("LeaveNoteItem");