
import { defineComponent, inject, onMounted } from "vue";
import { ContactFormInfo, ContactFormInfoKey } from "@/modules/contactForm";
import * as Yup from "yup";
import { checkEmail } from "@/commons/inputCheckCommon";
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { SiteLinkage } from "@/commons/interfaces";
import { loginStatus } from "@/modules/loginStatus";


export default defineComponent({
    name: "Contact",
    components: {
        Form,
        Field
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        // バリデーションスキーマ
        const schema = Yup.object().shape({
            lastName: Yup.string().matches(/^[^<>]*$/, "お名前（姓）に < と > は使用できません。").required("お名前（姓）は必須項目です"),
            firstName: Yup.string().matches(/^[^<>]*$/, "お名前（名）に < と > は使用できません。").required("お名前（名）は必須項目です"),
            lastKana: Yup.string().matches(/^[^<>]*$/, "ふりがな（せい）に < と > は使用できません。"),
            firstKana: Yup.string().matches(/^[^<>]*$/, "ふりがな（めい）に < と > は使用できません。"),
            email: checkEmail,
            service: Yup.string().required("ご利用サービスは必須項目です"),
            subject: Yup.string().required("お問い合わせ項目は必須項目です"),
            detail: Yup.string().required("お問い合わせ内容は必須項目です"),
        });

        const serviceSelection: {text: string; value: string}[] = [{ text: "選択してください", value: "" }];
        const getServiceDispStr = (nameJp: string, name: string) => nameJp == name ? nameJp : nameJp + "(" + name + ")";
        store.state.siteLinkage.forEach((site: SiteLinkage) => {
            // 親子構成になっている場合
            if (site.children && site.children.length > 0) {
                const childrenServiceNames = site.children.map((child) => getServiceDispStr(child.name_jp!, child.name!));
                const dispStr = getServiceDispStr(site.name_jp!, site.name!) + "（" + childrenServiceNames.join("、") + "）";
                serviceSelection.push({ text: dispStr, value: dispStr });
            } else {
                const dispStr = getServiceDispStr(site.name_jp!, site.name!);
                serviceSelection.push({ text: dispStr, value: dispStr });
            }
        });
        serviceSelection.push({ text: "上記以外のお問い合わせ", value: "上記以外のお問い合わせ" });
        
        const subjectSelection = [
            { text: "選択してください", value: "" },
            { text: "新規登録についてのお問い合わせ", value: "新規登録についてのお問い合わせ" },
            { text: "利用方法についてのお問い合わせ", value: "利用方法についてのお問い合わせ" },
            { text: "退会についてのお問い合わせ", value: "退会についてのお問い合わせ" },
            { text: "サービス、サイトへのご意見・ご要望", value: "サービス、サイトへのご意見・ご要望" },
            { text: "その他", value: "その他" },
        ];

        // 問い合わせフォームデータ取得
        const { contactFormItems, storeStorage } = inject(ContactFormInfoKey) as ContactFormInfo;

        // 確認画面遷移
        const confirm = () => {
            storeStorage();
            router.push({ name: "ContactConfirm" });
        };

        onMounted(() => {
            if (loginStatus.value) {
                contactFormItems.email = store.state.userInfo.baseInfo.email;
            }
        });

        return {
            schema,
            serviceSelection,
            subjectSelection,
            contactFormItems,
            confirm
        };
    },
});
