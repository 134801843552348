
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { SiteLinkage } from "@/commons/interfaces";

export default defineComponent({
    name: "RimacomiProfileCompleteCustom",
    setup() {
        const store = useStore();
        const { linkageInfo } = LinkageItem.inject();
        const siteName = store.state.siteLinkage.find((site: SiteLinkage) => site.link_id === linkageInfo.linkageId)?.name;
        return {
            siteName,
        };
    }
});
