
import { defineComponent, provide } from "vue";
import { CognitoItemKey, createCognitoItem } from "@/modules/cognitoItem";
import { ImageItemKey, createImageItem } from "@/modules/imageInfo";

export default defineComponent({
    name: "LinkageSignupParent",
    setup() {
        provide(CognitoItemKey, createCognitoItem());
        // プロフィール画像項目の有無に関係なく、provide()を行う
        provide(ImageItemKey, createImageItem());
        return {};
    }
});
