
import { useSidClient } from "@/plugins/auth";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { SID_ERROR_MSG_NOT_AUTHENTICATED, SID_ERROR_MSG_UNAUTHORIZED } from "@sid/sid_client";
import { Form, Field } from "vee-validate";
import { checkConfirmationCode } from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import useDeterring from "@/commons/useDeterring";
import { UserBaseItem } from "@/modules/userBaseItem";

export default defineComponent({
    name: "ChangeEmailConfirm",
    components: {
        Form,
        Field
    },
    setup() {
        const sidClient = useSidClient();
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const { isDeterringRef, asyncDeterringWrap } = useDeterring();

        const userBaseItem = UserBaseItem.inject();
        const newEmailRef = ref("");
        const confirmationCodeRef = ref("");
        const errorMsgRef = ref("");

        const schema = Yup.object().shape({
            confirmationCode: checkConfirmationCode
        });

        /**
         * 送信ボタン押下時処理
         * @async
         */
        const confirmConfirmationCode = async () => {
            // Emailアドレス変更による検証
            await sidClient.verifyEmail(confirmationCodeRef.value)
                .then(() => {                    
                    // メールアドレス変更完了画面へ遷移
                    router.push({ name: "ChangeEmailComplete" });
                })
                .catch(reason => {
                    if (reason.message === SID_ERROR_MSG_NOT_AUTHENTICATED || reason.message === SID_ERROR_MSG_UNAUTHORIZED) {
                        // ログイン画面へ遷移
                        router.push({ name: "Login" });
                    } else if (reason.response?.data === "invalid verification code") {
                        errorMsgRef.value = "コードが確認できませんでした。正しいコードを入力して再実施ください。";
                    } else {
                        errorMsgRef.value = "メールアドレス変更に失敗しました。後ほど再度お試し下さい。";
                    }
                })
                .finally(async () => {
                    // 基本属性情報再取得して保持
                    await store.dispatch("fetchUserInfo");
                    userBaseItem.setUserInfo(store.state.userInfo);
                });
        };

        /**
         * 確認コード再送ボタン押下時処理
         * @async
         */
        const resendAttrConfirmationCode = async () => {
            // メールアドレス変更再実施
            await sidClient.changeEmail(newEmailRef.value)
                .catch(reason => {
                    if (reason.message === SID_ERROR_MSG_NOT_AUTHENTICATED || reason.message === SID_ERROR_MSG_UNAUTHORIZED) {
                        // ログイン画面へ遷移
                        router.push({ name: "Login" });
                    } else if (reason.response?.data === "the email has already been changed") {
                        errorMsgRef.value = "既にメールアドレス変更は完了しています。";
                    } else if (reason.response?.data === "the email address is not allowed") {
                        errorMsgRef.value = "このメールアドレスへの変更はできません。";
                    } else {
                        errorMsgRef.value = "メールアドレス変更に失敗しました。後ほど再度お試し下さい。";
                    }
                });
        };

        /**
         * キャンセルボタン押下時処理
         * @async
         */
        const cancelPassword = async() => {
            router.push({ name: "ChangeEmail" });
        };

        /**
         * ページを離れる場合の確認アラート起動
         * @event
         */
        const confirmSave = (event: any) => {
            event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
        };

        onMounted(() => {
            newEmailRef.value = route.query.email as string;
            window.addEventListener("beforeunload", confirmSave);
        });

        onUnmounted(() => {
            window.removeEventListener("beforeunload", confirmSave);
        });

        return {
            isDeterringRef,
            asyncDeterringWrap,
            newEmailRef,
            confirmationCodeRef,
            errorMsgRef,
            schema,
            confirmConfirmationCode,
            resendAttrConfirmationCode,
            cancelPassword
        };
    },
});
