
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { PREFECTURE_LIST } from "@/commons/constants";
import { Field, Form } from "vee-validate";
import {
    checkAddressNotReqd,
    checkBirthdayIsReqd,
    checkBloodTypeNotReqd,
    checkBuildingNotReqd,
    checkFirstKanaNotReqd,
    checkFirstNameNotReqd,
    checkGenderNotReqd,
    checkHasChildNotReqd,
    checkIsMarriedNotReqd,
    checkLastKanaNotReqd,
    checkLastNameNotReqd,
    checkNicknameNotReqd,
    checkPrefectureIsReqd,
    checkTelNotReqd,
    checkZipCodeNotReqd
} from "@/commons/inputCheckCommon";
import * as Yup from "yup";
import { sidClient } from "@/plugins/auth";
import moment from "moment";
import { Core as YubinBangoCore } from "yubinbango-core2";
import { LinkageItem } from "@/modules/linkage/linkageInfo";
import { isSiteRegistered } from "@/commons/commonUtility";
import { injectSiteUniqItem, injectUserBaseItem } from "@/commons/linkage/linkageCommon";
import { useDateForm } from "@/commons/dateForm";
import { UserLee } from "@/commons/interfaces";
import { checkLeeIsSendMailMagazineIsReqd, checkLeeJobIsReqd, checkLeeNickNameIsReqd } from "@/commons/inputCheckSiteUniqueInfo";
import LinkageTerms from "@/components/Linkage/LinkageTerms.vue";

const JOB_LIST = [
    "学生",
    "会社員（役員・管理職）",
    "会社員（総合職・一般職）",
    "公務員",
    "自営業・フリーランス",
    "契約・派遣社員",
    "パート・アルバイト",
    "専業主婦",
    "家事手伝い・無職",
    "その他"
];

export default defineComponent({
    name: "LeeProfile",
    components: {
        Form,
        Field,
        LinkageTerms
    },
    props: {
        // 次画面の遷移先
        nextRoute: { type: String, required: true },
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const { setYear, setDate, date, years, months, dates, birthday } = useDateForm();
        const { linkageInfo, routeNames } = LinkageItem.inject();

        const userBaseItem = injectUserBaseItem(linkageInfo.linkageId);
        const siteUniqItem = injectSiteUniqItem(linkageInfo.linkageId);
        const prefectureList = PREFECTURE_LIST;

        const userBase = userBaseItem.userData;
        const userLee = siteUniqItem.userData as UserLee;

        const formRef = ref<any>();
        // 初回サービス登録判定
        const firstServiceReg = route.name === `${routeNames.LOGIN_PROFILE}Child` && !isSiteRegistered(linkageInfo.linkageId, userBase);
        // 会員移行後初回ログイン判定（基本属性情報：存在する+siteInfoにLEEの登録が無い、サイト固有情報：存在する）
        const firstLoginAfterMgn = userBase.identityId && userLee.identityId && !isSiteRegistered(linkageInfo.linkageId, userBase);

        // 新規会員登録時の誕生日初期選択値
        if (!userBase.identityId) {
            setYear(1990);
        }

        const moveConfirm = async () => {
            userBaseItem.storeStorage();
            siteUniqItem.storeStorage();
            await router.push({ name: props.nextRoute });
        };

        const schema = Yup.object().shape({
            lastName: checkLastNameNotReqd,
            firstName: checkFirstNameNotReqd,
            lastKana: checkLastKanaNotReqd,
            firstKana: checkFirstKanaNotReqd,
            nickname: checkNicknameNotReqd,
            gender: checkGenderNotReqd,
            birthday: checkBirthdayIsReqd,
            zipCode: checkZipCodeNotReqd,
            prefecture: checkPrefectureIsReqd,
            address: checkAddressNotReqd,
            building: checkBuildingNotReqd,
            tel: checkTelNotReqd,
            isMarried: checkIsMarriedNotReqd,
            hasChild: checkHasChildNotReqd,
            bloodType: checkBloodTypeNotReqd,
            job: checkLeeJobIsReqd,
            leeNickName: checkLeeNickNameIsReqd,
            isSendMailMagazine: checkLeeIsSendMailMagazineIsReqd
        });

        /**
         * 住所自動入力
         */
        const addressAutoFill = () => {
            const zipCode = userBase.baseInfo.zipCode ? userBase.baseInfo.zipCode : undefined;
            new YubinBangoCore(zipCode, (addr: any) => {
                userBase.baseInfo.prefecture = addr.region; // 都道府県
                userBase.baseInfo.address = addr.locality; // 市区町村
                userBase.baseInfo.address += addr.street; // 町域
            });
        };

        const birthdayValidate = () => {
            formRef.value.validateField("birthday");
        };

        onMounted(async () => {
            // 誕生日をセット
            setDate(userBase.commonAttrInfo.birthday);

            // 基本属性情報の有無を判断
            if (userBase.identityId) {              
                // ニックネーム
                userBase.commonAttrInfo.nickName = (userBase.commonAttrInfo.nickName || "")
                    .replaceAll("&lt;", "<")
                    .replaceAll("&gt;", ">");

                if (userLee.identityId) {
                    userLee.nickName = (userLee.nickName || "")
                        .replaceAll("&lt;", "<")
                        .replaceAll("&gt;", ">");

                    formRef.value.setFieldValue("isSendMailMagazine", userLee.isSendMailMagazine);
                }

                const ret = await formRef.value.validate();
                // vee-validate 4.6.7 以降、slotのerrorsと連動していないため、setする
                formRef.value.setErrors(ret.errors);
            } else {
                // メールアドレスをセット
                try {
                    const userData = await sidClient.getUserData();
                    const email = userData.UserAttributes.filter((obj) => obj.Name === "email")[0].Value;
                    userBase.baseInfo.email = email;
                } catch (e) {
                    await router.push({
                        name: routeNames.SIGNUP_IDENTIFICATION,
                        query: { retUrl: router.currentRoute.value.name as string },
                        params: { message: "認証の有効期限が切れました。再ログイン後にもう一度最初から操作をお願いします。" },
                    });
                }
            }

            watchEffect(() => {
                const tmpDate = moment(`${date.year}-${date.month}-${date.day}`, "YYYY-M-D", true);
                userBase.commonAttrInfo.birthday = tmpDate.isValid() ? tmpDate.format("YYYY-MM-DD") : null;
            });
        });

        return {
            userBase,
            userLee,
            date,
            birthday,
            years,
            months,
            dates,
            prefectureList,
            JOB_LIST,
            schema,
            addressAutoFill,
            moveConfirm,
            birthdayValidate,
            firstServiceReg,
            firstLoginAfterMgn,
            formRef,
        };
    },
});
